import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { apiSlice, useRegisterSubjectMutation } from "../../apis/apiSlice";
import RadiationSymbol from '../../images/Radiation_warning_symbol2.svg'
import { AppDispatch, RootState } from '../../store';
import { selectDeviceRunnerActive, setAcquisitionActive } from '../Akquisition/AcquisitionSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import Search from "./Search";
import './ActionButton.scss'
import { Constants } from '../../Constants';
//import { useTranslation } from 'react-i18next';
import { useORTranslation } from '../Localization/ORLocalization';
import { getImagePathForCurrentTheme } from './OrdersSlice';
import { useTriggerWorklistAutoImportMutation,} from '../../apis/apiSlice';

interface ActionBtnProps {
	title: string
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const ActionButton = (props: ActionBtnProps) => {

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const location = useLocation();

	//const { t } = useTranslation();
	const { t } = useORTranslation(['ActionButton']);

	const [registerSubject] = useRegisterSubjectMutation();

	const isDeviceRunnerActive: boolean = useAppSelector((state) => selectDeviceRunnerActive(state));
	const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const [isWorklistActive, setIsWorklistActive] = useState<boolean>(false);
    const [TriggerWorklist] = useTriggerWorklistAutoImportMutation();

	const handleClick = async (e: React.MouseEvent) => {


		const subjectName = {familyName: "Emergency"};
		const submitSubject = {details: {type: "patient", sex: 'other'}, subjectNames: [subjectName]};
        const registerSubjectResult = structuredClone((await registerSubject({ body: submitSubject }))?.data);

		const newStudyId: any = await dispatch(setAcquisitionActive({ setAcquistionButtonActive: true, studyId: undefined, workitemId: "-1", studyInfo: {subject: registerSubjectResult } }));
		dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 1 });
		if (newStudyId?.payload?.studyId) {
			navigate(`/orders/${newStudyId?.payload?.studyId}/${location?.search ?? ''}`);
		}
	}

	const actionbutton = () =>
		<div className={"action-button-div"}>
			<button
				className={isDeviceRunnerActive ? "action-button" : "action-button_no_device_runner"}
				onClick={handleClick}>
				{isDeviceRunnerActive ?
					<img src={RadiationSymbol} alt="x-ray" /> :
					<img
						src={`${imagePathForCurrentTheme}layers-svgrepo-com.svg`}
						onError={(event: any) => { event.target.src = "/images/layers-svgrepo-com.svg"; event.onerror = null }}
						alt="new study" />
				}
				<span className="button-main-title">{isDeviceRunnerActive ? t('expose') : 'new study'}</span>
				{/* <span className="button-main-title">{isDeviceRunnerActive ? props.title : 'new study'}</span> */}
				{isDeviceRunnerActive ?
					<span className="button-sub-title">{'[' + t(`new${Constants.ORDER_IDENTIFIER}`) + ']'}</span> : null
				}
			</button>
		</div>

	const onRefreshWorklist = async (evt: React.MouseEvent<Element>) => {
	    setIsWorklistActive(true);
		TriggerWorklist({}).unwrap();
		setTimeout(()=>{dispatch(apiSlice.util.invalidateTags(['Studies', 'Study']))}, 2000);
		setTimeout(()=>{setIsWorklistActive(false)}, 3000);
	};

	const actionbuttonHuman = () =>
		<>
			<button
				className={isDeviceRunnerActive ? "venaDefaultButton action-button" : " venaDefaultButton action-button_no_device_runner"}
				onClick={handleClick}>
				{isDeviceRunnerActive ?
					<img className="action-button-img" src={RadiationSymbol} alt="x-ray" /> :
					<img
						src={`${imagePathForCurrentTheme}layers-svgrepo-com.svg`}
						onError={(event: any) => { event.target.src = "/images/layers-svgrepo-com.svg"; event.onerror = null }}
						alt="new study" />
				}
				<span className="button-main-title">{isDeviceRunnerActive ? t('Emergency') : 'new study'}</span>
				{/* <span className="button-main-title">{isDeviceRunnerActive ? props.title : 'new study'}</span> */}
				{/* {isDeviceRunnerActive ?
					<span className="button-sub-title">{'[' + t(`new${Constants.ORDER_IDENTIFIER}`) + ']'}</span> : null
				} */}
			</button>
			<button className="newSubject" onClick={() => dispatch({ type: "ImageDisplay/setSubjectDialogVisible", payload: 1 })}>
				<label className="newSubjectLabel">{"New Study"}</label>
				<div className="newSubjectImgWrapper">
					<img className="newSubjectImg" src={`${imagePathForCurrentTheme}Neuer_Roentgenauftrag.svg`}
						onError={(event: any) => { event.target.src = "/images/Neuer_Roentgenauftrag.svg"; event.onerror = null }}
						alt="Anzeige Bilduebersicht aktiv.svg" />
				</div>
			</button>
			<button className="worklist" onClick={onRefreshWorklist}>
				<label className="worklistLabel">{"Worklist"}</label>
				<div className="worklistImgWrapper">
					<img className="worklistImg" src={isWorklistActive?`${imagePathForCurrentTheme}refresh-rotating.svg`:`${imagePathForCurrentTheme}refresh.svg`}
						onError={(event: any) => { event.target.src = isWorklistActive?"/images/refresh-rotating.svg":"/images/refresh.svg"; event.onerror = null }}
						alt="Anzeige Worklistimport aktiv.svg" />
				</div>
			</button>
		</>

	return (
		<div className={"action-button-search-div"}>
			<div className={"action-button-div"}>
				{actionbutton()}
			</div>
			<div className={"action-button-div-human"}>
				{actionbuttonHuman()}
			</div>
			{/* <button className="newSubject" onClick={() => dispatch({ type: "ImageDisplay/setSubjectDialogVisible", payload: 1 })}>
				{"New Study"}
			</button> */}
			<Search />
		</div >
	);
}

export default ActionButton;
