import { useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import './ExposureParameters2.scss';
import AECButton from './AECButton';
import { getImagePathForCurrentTheme, getSelectedWorkitem } from '../OrderList/OrdersSlice';
import { apiSlice, device, deviceSet, property } from '../../apis/apiSlice';
import { getTechniqueParameters } from './GeneratorTechniqueParametersSlice';
import { selectCurrentDeviceProperties, selectDeviceProperties, selectGeneratorForDeviceSet } from './AcquisitionSlice';
import { formatISO } from 'date-fns';
import { Constants } from '../../Constants';
import { getCurrentProtocolStepNumber } from '../ImageDisplay/ImageDisplaySlice';
import { getColorCode, getValueForProperty } from './Device';
import { useORTranslation } from '../Localization/ORLocalization';

interface ExposureParameters2Props {
    orderId: string;
    deviceSet: any;
    device: device | deviceSet;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const ExposureParameters2 = (props: ExposureParameters2Props) => {

    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['Acquisition', 'common']);

    const rootDivElement = useRef<HTMLDivElement>(null);

    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
    const generatorForDeviceSet: any = useAppSelector((state) => selectGeneratorForDeviceSet(state, props.deviceSet?.id));
    const currentProtocolSettingsStepNumber: number = useAppSelector((state) => getCurrentProtocolStepNumber(state, Constants.ACQUISITION_STEP_TYPE, 'scheduled', 'rejected'));
    const currentPropertyValues: [string, any][] = JSON.parse(useAppSelector((state) => selectCurrentDeviceProperties(state, props.device.config.endpoint)));
    const propertyDefs: [string, property][] = JSON.parse(useAppSelector((state) => selectDeviceProperties(state, props.device.config.endpoint)));

    const [open, setOpen] = useState(false);

    const handleOpen = (evt: any) => {
        setOpen(!open);
    };


    const handleGridClick = (evt: React.MouseEvent<Element>) => {
        const currentProtocolStep = currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber];
        const newProtocolStep = structuredClone(currentProtocolStep);

        if (currentProtocolStep?.scheduled?.setup) {
            console.log(currentProtocolStep?.scheduled?.setup);
            newProtocolStep.scheduled.setup.grid = currentProtocolStep?.scheduled?.setup?.grid === 1 ? 0 : 1;

            const action_ref = currentWorkitem?.id + "_" + formatISO(new Date());
            const gridFactor = currentProtocolStep?.scheduled?.setup?.grid === 1 ? 2 : 0.5;
            if (newProtocolStep?.scheduled?.generator) {
                newProtocolStep.scheduled.generator.masWithoutAEC = newProtocolStep?.scheduled?.generator.masWithoutAEC * gridFactor;
                newProtocolStep.scheduled.generator.masWithAEC = newProtocolStep?.scheduled?.generator.masWithAEC * gridFactor;
            }
            dispatch(getTechniqueParameters({
                voltage: currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.kv,
                exposure: (currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber === 0) ? currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.masWithoutAEC * gridFactor : currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.masWithAEC * gridFactor,
                generatorEndpoint: generatorForDeviceSet?.config?.endpoint, action_ref
            }));

            dispatch(apiSlice.endpoints.updateWorkitemProtocolStep.initiate(
                {
                    workitemId: currentWorkitem?.id,
                    stepNumber: currentProtocolSettingsStepNumber,
                    body: {
                        protocol: newProtocolStep
                    }
                }
            ));

            dispatch(
                apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                    const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                    if (currentWorkitem?.id && workitemList && workitemList.includes(currentWorkitem?.id)) {
                        const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitem?.id);
                        if (tmpWorkitem?.data) {
                            tmpWorkitem.data.protocol.steps[currentProtocolSettingsStepNumber] = newProtocolStep;
                        }
                    }
                    return data;
                })
            );
        }
    }

    useEffect(() => {
		const onBodyClick = (event: Event) => {
			if (rootDivElement.current?.contains(event.target as Node)) {
				return;
			}
			setOpen(false);
		};
		document.body.addEventListener("click", onBodyClick, { capture: true });
		return () => {
			document.body.removeEventListener("click", onBodyClick, { capture: true });
		};
	}, []);

    return (
        <div className="exposureParameters2" ref={rootDivElement}>

            <div className="exposureParameters2_AEC_Wrapper">

                <div className="exposureParameters2_AEC">

                    <div className="grid">
                        <button onClick={handleGridClick} className={currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.grid === 0 ? "gridButton_selected" : "gridButton"}>
                            <img className="gridButtonImg"
                                src={`${imagePathForCurrentTheme}layout-4lines-svgrepo-com.svg`}
                                onError={(event: any) => { event.target.src = "/images/layout-4lines-svgrepo-com.svg"; event.onerror = null }}
                                alt="tag browser" />
                        </button>
                    </div>

                    <div className="AECButtonWrapper" onMouseDown={handleOpen}>

                        <AECButton preventClick={true} measuringChamber={currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber} orderId={props.orderId} deviceSet={props.deviceSet} />
                        <div className={open ? "AECSelection-visible" : "AECSelection-invisible"}  >
                            <AECButton measuringChamber={0} orderId={props.orderId} deviceSet={props.deviceSet} />
                            <AECButton measuringChamber={1} orderId={props.orderId} deviceSet={props.deviceSet} />
                            <AECButton measuringChamber={2} orderId={props.orderId} deviceSet={props.deviceSet} />
                            <AECButton measuringChamber={3} orderId={props.orderId} deviceSet={props.deviceSet} />
                            <AECButton measuringChamber={4} orderId={props.orderId} deviceSet={props.deviceSet} />
                            <AECButton measuringChamber={5} orderId={props.orderId} deviceSet={props.deviceSet} />
                            <AECButton measuringChamber={6} orderId={props.orderId} deviceSet={props.deviceSet} />
                            <AECButton measuringChamber={7} orderId={props.orderId} deviceSet={props.deviceSet} />
                        </div>
                    </div>
                    {propertyDefs?.find((property: [string, property]) => property[0] === 'focus') !== undefined ?
                        <div className="focus2">
                            <button className="focusButton2"
                                id={getColorCode(props.device.category, getValueForProperty('status', currentPropertyValues)?.status_code).toString().toUpperCase()}
                                title={`${t(propertyDefs?.find((property: [string, property]) => property[0] === 'focus')![1]?.description)}`}>
                                <img className="focusButtonImg2"
                                    src={getValueForProperty(propertyDefs?.find((property: [string, property]) => property[0] === 'focus')![0], currentPropertyValues) === 'small' ?
                                        `${imagePathForCurrentTheme}square-svgrepo-com.svg` : `${imagePathForCurrentTheme}square-svgrepo-com2.svg`}
                                    onError={(event: any) => {
                                        event.target.src = getValueForProperty(propertyDefs?.find((property: [string, property]) => property[0] === 'focus')![0], currentPropertyValues) === 'small' ?
                                            "/images/square-svgrepo-com.svg" : "/images/square-svgrepo-com2.svg"; event.onerror = null
                                    }}
                                    alt="focusButtonImg" />

                            </button>
                        </div>
                        : null}
                </div>
            </div>
        </div>
    );
}

export default ExposureParameters2;
