import { AppDispatch, RootState } from '../../store';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, subDays, subMonths } from 'date-fns';
import { apiSlice } from '../../apis/apiSlice';
import { useEffect, useState } from 'react';
import { DateRangeT, selectDateMenuEntry, selectSearchDateRange, selectSearchString, selectTheme } from './MainOrderListSlice';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './Search.scss'
import CommonDialog from '../Utils/CommonDialog';
import DateRangeSelector from './DateRangeSelector';
import { useORTranslation } from '../Localization/ORLocalization';
import { getImagePathForCurrentTheme } from './OrdersSlice';

interface SearchProps {
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const Search = (props: SearchProps) => {

	const { t } = useORTranslation(['Search', 'common']);
	const { language } = useORTranslation([])

	const dispatch = useAppDispatch();
	const [datePickerActive, setDatePickerActive] = useState<boolean>(false);

	const [haveCustomDateRange, setHaveCustomDateRange] = useState<boolean>(false);
	const [customDateRange, setCustomDateRange] = useState<DateRangeT>({ startDate: new Date(), endDate: new Date() });
	const [previousDateRangeSelection, setPreviousDateRangeSelection] = useState<string>("all");
	const [rangeSelectOptions, setRangeSelectOptions] = useState<{ key: string, text: string }[]>(
		[
			{ key: "all", text: t("all") },
			{ key: "today", text: t("today") },
			{ key: "yesterday", text: t("yesterday") },
			{ key: "thisweek", text: t("thisweek") },
			{ key: "lastweek", text: t("lastweek") },
			{ key: "thismonth", text: t("thismonth") },
			{ key: "lastmonth", text: t("lastmonth") },
			{ key: "select", text: t("select") },
		]
	);

	const searchString: string = useAppSelector((state) => selectSearchString(state));
	const searchDateRange: DateRangeT = useAppSelector((state) => selectSearchDateRange(state));
	const selectedDateMenuEntry: string | undefined = useAppSelector((state) => selectDateMenuEntry(state));
	const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection'
		}
	]);

	const handleSearchButton = async (evt: any) => {
		evt.stopPropagation();
		evt.preventDefault();
		dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
	}

	const handleKeyDown = (event: any) => {
		if (event.key === 'Enter') {
			dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
		}
	}

	// bound to the search string input field this function fires searches for
	// input strings with length >= 3 and if the input field is cleared
	const handleSearch = async (evt: any) => {
		evt.stopPropagation();
		evt.preventDefault();
		const newSearchString = evt.target.value;
		if (newSearchString) {
			//console.log("Got new search string:" + newSearchString);
			dispatch({ type: "MainOrderListColumns/setSearchString", payload: { "searchString": newSearchString } });

			if (newSearchString.length > 2)
				dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
		} else {
			//console.log("Resetting search string...");
			dispatch({ type: "MainOrderListColumns/setSearchString", payload: { "searchString": "" } });
			dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
		}
	}

	const handleDateMenuSelection = async (evt: any) => {
		var value = evt.target.value;
		var dateRange = undefined;
		var today: Date = new Date();
		var haveRange = true;

		if (value !== 'select') {
			setPreviousDateRangeSelection(value);
		}

		switch (value) {
			case 'today':
				dateRange = {
					startDate: today,
					endDate: today
				}
				break;
			case 'yesterday':
				var yesterday: Date = new Date();
				yesterday.setDate(today.getDate() - 1);
				dateRange = {
					startDate: yesterday,
					endDate: yesterday
				}
				break;
			case 'thisweek':
				dateRange = {
					startDate: startOfWeek(Date.now()),
					endDate: endOfWeek(Date.now())
				}
				break;
			case 'lastweek':
				dateRange = {
					startDate: subDays(startOfWeek(Date.now()), 7),
					endDate: endOfWeek(subDays(startOfWeek(Date.now()), 7))
				}
				break;
			case 'thismonth':
				dateRange = {
					startDate: startOfMonth(Date.now()),
					endDate: endOfMonth(Date.now())
				}
				break;
			case 'lastmonth':
				dateRange = {
					startDate: subMonths(startOfMonth(Date.now()), 1),
					endDate: endOfMonth(subMonths(startOfMonth(Date.now()), 1))
				}
				break;
			case 'select':
				setDateRange(
					[{
						startDate: new Date(),
						endDate: new Date(),
						key: 'selection'
					}]
				)
				setDatePickerActive(true);
				haveRange = false;
				break;
			case 'custom':
				dateRange = customDateRange;
				break;
		} // switch


		dispatch({ type: "MainOrderListColumns/setSelectedDateMenuEntry", payload: value === 'select' ? previousDateRangeSelection : value });

		if (haveRange) {
			if (value === 'custom')
				setHaveCustomDateRange(true);
			else
				setHaveCustomDateRange(false);
			dispatch({ type: "MainOrderListColumns/setSearchDateRange", payload: dateRange });
			//setDatePickerActive(false);
			dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
		}
		/* console.log(previousDateRangeSelection);
		console.log(haveRange);
		console.log(dateRange); */


		return;
	} // handleDateMenuSelection

	const getDateRangeString = (dataRangeArg: DateRangeT) => {
		// TODO trim string avoiding unneeded info, e.g. the year when all years in the range are current
		if (dataRangeArg.startDate.toDateString() !== dataRangeArg.endDate.toDateString())
			return ("" + dataRangeArg.startDate.toDateString() + " - " + dataRangeArg.endDate.toDateString());
		else
			return ("" + dataRangeArg.startDate.toDateString());
	}

	const getDateRangeSelectOptions = () => {
		return (<>
			{rangeSelectOptions.map((val: { key: string, text: string }, i) => <option value={val.key} key={i.toString()}>{val.text}</option>)}
		</>
		);
	} // getDateRangeSelectOptions

	const getDateRangeSelector = () => {
		if (haveCustomDateRange)
			return (<select onChange={handleDateMenuSelection} className="dateRangeSelector" value='custom'>
				{getDateRangeSelectOptions()}
			</select>);
		else
			return (<select onChange={handleDateMenuSelection} className="dateRangeSelector" value={selectedDateMenuEntry}>
				{getDateRangeSelectOptions()}
			</select>);
	}

	const handleApply = () => {
		if (dateRange && dateRange.length > 0) {
			dispatch({ type: "MainOrderListColumns/setSearchDateRange", payload: dateRange[0] });
			setHaveCustomDateRange(true);
			const currentDateRange = { startDate: dateRange[0].startDate, endDate: dateRange[0].endDate };
			setCustomDateRange(currentDateRange);
			setRangeSelectOptions([...(rangeSelectOptions.filter((val: { key: string, text: string }) => val.key !== "custom")),
			{ key: "custom", text: getDateRangeString(currentDateRange) }])
		}
		dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
	}

	const handleDateRangePickerClose = () => {
		setDatePickerActive(false);
		const element = document.getElementsByClassName("dateRangeSelector");
		if (element?.item(0) && (element.item(0) as HTMLSelectElement)) {
			(element.item(0) as HTMLSelectElement).value = previousDateRangeSelection;
		}
	};

	const handleDateRangeSelection = async (ranges: any) => {
		setDateRange([ranges.selection]);
	}

	useEffect(() => {
		// console.log(searchDateRange);
		// console.log(selectedDateMenuEntry);
		// console.log(rangeSelectOptions);
		if (searchDateRange) {
			setCustomDateRange({ ...searchDateRange });
			setRangeSelectOptions([...(rangeSelectOptions.filter((val: { key: string, text: string }) => val.key !== "custom")),
			{ key: "custom", text: getDateRangeString(searchDateRange) }])
		}

	}, []);

	useEffect(() => {
		setRangeSelectOptions([
			{ key: "all", text: t("all") },
			{ key: "today", text: t("today") },
			{ key: "yesterday", text: t("yesterday") },
			{ key: "thisweek", text: t("thisweek") },
			{ key: "lastweek", text: t("lastweek") },
			{ key: "thismonth", text: t("thismonth") },
			{ key: "lastmonth", text: t("lastmonth") },
			{ key: "select", text: t("select") },
		]);
	}, [language, t]);

	return (
		<div className={"studySearch"}>
			<div className={"search-div"}>
				<div className={"search-calender"}>
					<img className="search-calender-img" src={`${imagePathForCurrentTheme}Group 2.svg`}
						onError={(event: any) => { event.target.src = "/images/Group 2.svg"; event.onerror = null }}
						alt="Group 2.svg" onClick={(evt: any) => setDatePickerActive(true)}/>
				</div>
				<div className="dateRangeSelectorDiv">
					{getDateRangeSelector()}
					{/* <img src="/images/calendar-svgrepo-com.svg" alt="calendar-svgrepo-com" /> */}
				</div>
				<div className={"search-fields"}>
					<input type="search" className="searchInput" name="Search" onChange={handleSearch} value={searchString}
						onKeyDown={handleKeyDown} placeholder={t("Search") as string} />
					<button className="searchButton" onClick={handleSearchButton}>
						<img
							src={`${imagePathForCurrentTheme}search.svg`}
							onError={(event: any) => { event.target.src = "/images//search.svg"; event.onerror = null }}
							alt="initiate search" />
					</button>
				</div>

			</div>
			<CommonDialog onClose={handleDateRangePickerClose} title={t('dateRangePicker')} visible={datePickerActive} okText={t("apply", { ns: 'common' })} cancelText={t("cancel", { ns: 'common' })}
				onOk={handleApply}
				default={{ x: (window.innerWidth - 450) / 20, y: (window.innerHeight - 450) / 2, width: 355, height: undefined }} childProps={{}}>
				<DateRangeSelector
					handleDateRangeSelection={(ranges: any) => handleDateRangeSelection(ranges)}
					dateRange={dateRange}
				/>
			</CommonDialog>
		</div>
	);
}

export default Search;
