import React, { useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import OrderListEntry from './OrderListEntry';
import './OrderList.scss';
import type { AppDispatch, RootState } from '../../store'
import { useEffect } from 'react';
import { apiSlice, study, useConfigQuery, useSearchStudiesQuery } from '../../apis/apiSlice';
import { useQuery } from '../ImageDisplay/Viewer';
import ExportDialog from '../Preview/ExportDialog';
import FinalizeStudyDialog from '../Preview/FinalizeStudyDialog';
import { getImagePathForCurrentTheme, getThemes, selectCurrentOrder } from './OrdersSlice';
import OrderInfoPanel from './OrderInfoPanel';
import { MainOrderListColumn, selectAllowComplete, selectFormatString, selectOrderlistIndex, selectTheme } from './MainOrderListSlice';
import CommonDialog from '../Utils/CommonDialog';
import { selectExportDialogVisible, selectFinalizeStudyDialogVisible } from '../ImageDisplay/ImageDisplaySlice';
import { useORTranslation } from '../Localization/ORLocalization';

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const MainOrderList = () => {
	const dispatch = useAppDispatch();
	const { t } = useORTranslation(['OrderList', 'ExportDialog', 'FinalizeStudyDialog', 'common']);

	const currentOrderId: string | undefined = useAppSelector((state) => selectCurrentOrder(state));
	const orderlistIndex: number = useAppSelector((state) => selectOrderlistIndex(state));
	const formatString: string = useAppSelector((state) => selectFormatString(state));
	const isExportDialogVisible: number = useAppSelector((state) => selectExportDialogVisible(state));
	const isFinalizeStudyDialogVisible: number = useAppSelector((state) => selectFinalizeStudyDialogVisible(state));
	const allowComplete: boolean = useAppSelector((state) => selectAllowComplete(state));
	const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
	const currentTheme: string = useAppSelector((state) => selectTheme(state));
	const themes: string[] = useSelector(getThemes);

	const [altColsActive, setAltColsActive] = useState<boolean>(false);

	const query = useQuery();
	const deviceRunnerPath: string | null = query?.get("device-runner");

	/* const {
		data,
		isSuccess,
	}: { data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } =
		useGetStudiesQuery(undefined, {
			refetchOnMountOrArgChange: false,
		}) */

	const {
		data: data_OrderlistCols,
		isSuccess: isSuccess_OrderlistCols,
	}: { data: any, isSuccess: boolean } = useConfigQuery({ file: 'Orderlist.json', path: themes?.length > 0 ? `Themes/${currentTheme}` : './' }, {
		refetchOnMountOrArgChange: true,
	})

	const {
		data,
		isSuccess,
	}: { data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } =
		useSearchStudiesQuery(undefined, {
			refetchOnMountOrArgChange: false,
		})


	const setSortUp = (column: number, sortUp: boolean) => {
		dispatch(
			apiSlice.util.updateQueryData('config', { file: 'Orderlist.json', path: themes?.length > 0 ? `Themes/${currentTheme}` : './' }, (data: any) => {
				if (data[orderlistIndex]?.cols[column]) {
					data[orderlistIndex].cols[column].sortUp = sortUp;
				}
				return data;
			})
		)
	}

	const setSelectedColumn = (selectedColumn: number) => {
		dispatch(
			apiSlice.util.updateQueryData('config', { file: 'Orderlist.json', path: themes?.length > 0 ? `Themes/${currentTheme}` : './' }, (data: any) => {
				for (let i: number = 0; i < data[orderlistIndex]?.cols?.length; i++) {
					if (i === selectedColumn) {
						data[orderlistIndex].cols[i].selected = true;
					} else {
						data[orderlistIndex].cols[i].selected = false;
					}
				}
				return data;
			})
		)
	}

	const handleClick = (evt: React.MouseEvent<Element> | React.TouchEvent, columnNumber: number, column: MainOrderListColumn, sortUp: boolean) => {
		const correctedColumnNumber = allowComplete ? columnNumber : columnNumber + 1;
		setSortUp(correctedColumnNumber, sortUp);
		if (column.sortstring) {
			if (sortUp)
				dispatch({ type: "MainOrderListColumns/setSortString", payload: column.sortstring + 'Ascending' });
			else
				dispatch({ type: "MainOrderListColumns/setSortString", payload: column.sortstring + 'Descending' });
		}
		dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
	};

	const handleColumnClick = (evt: React.MouseEvent<Element> | React.TouchEvent, columnNumber: number, column: MainOrderListColumn) => {

		const correctedColumnNumber = allowComplete ? columnNumber : columnNumber + 1;
		if (data_OrderlistCols[orderlistIndex]?.cols && data_OrderlistCols[orderlistIndex]?.cols[correctedColumnNumber]?.sortable) {
			setSelectedColumn(correctedColumnNumber);
			if (column.sortstring) {
				if (column.sortUp)
					dispatch({ type: "MainOrderListColumns/setSortString", payload: column.sortstring + 'Ascending' });
				else
					dispatch({ type: "MainOrderListColumns/setSortString", payload: column.sortstring + 'Descending' });
			}
			dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
		}
	};

	useEffect(() => {
		dispatch({ type: "ImageDisplay/setImageDescVisible", payload: false });
		dispatch({ type: "ImageDisplay/setOrderInfoVisible", payload: false });
		if (deviceRunnerPath && deviceRunnerPath.length > 0) {
			const proto: string = (window.location.protocol === 'https:') ? 'https://' : 'http://';
			const connection: string = proto + deviceRunnerPath;
			dispatch({ type: "Acquisition/setDeviceRunnerPath", payload: connection });
			dispatch({ type: "Acquisition/setDeviceRunnerActive", payload: true });
		}
		if (isSuccess && data) {
			dispatch({ type: "ImageDisplay/setTotalPages", payload: data?.totalPages });
			dispatch({ type: "ImageDisplay/setTotalElements", payload: data?.totalElements });
		}
	}, [dispatch, data, isSuccess, deviceRunnerPath]);

	useEffect(() => {
		dispatch({ type: "Orders/setMainOrderListOpen", payload: true });

		return () => {
			dispatch({ type: "ImageDisplay/setOrderInfoVisible", payload: false });
			dispatch({ type: "ImageDisplay/setExportDialogVisible", payload: 0 });
			dispatch({ type: "Orders/setMainOrderListOpen", payload: false });
		};
	}, [orderlistIndex]);

	const handleExportDialogClose = () => {
		dispatch({ type: "ImageDisplay/setExportDialogVisible", payload: 0 });
	};

	const handleFinalizeStudyDialogClose = () => {
		dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 0 });
	};

	const handleColumnSelectClick = async (e: React.MouseEvent) => {
		setAltColsActive(!altColsActive);
		dispatch({ type: "MainOrderListColumns/setOrderlistIndex", payload: altColsActive ? 0 : 1 });
		dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
	}

	const sortButtons = (sortUp: boolean, columnNumber: number, column: MainOrderListColumn) =>
		<React.Fragment>
			<button className="sortUpButton" onClick={(evt) => handleClick(evt, columnNumber, column, !sortUp)}>
				<img className="sortImage" src={sortUp ?
					`${imagePathForCurrentTheme}sort-up.svg` :
					`${imagePathForCurrentTheme}sort-down.svg`}
					onError={(event: any) => {
						event.target.src =
							sortUp ? "/images/sort-up.svg" : "/images/sort-down.svg";
						event.onerror = null
					}}
					title={sortUp ? t("sort-up") as string : t("sort-down") as string} alt="/images/sort-up.svg" />
			</button>
		</React.Fragment>;

	return (
		<>
			{isSuccess && isSuccess_OrderlistCols ?
				<div className='order-headers'>
					<ul className='header'>
						<li className="header-item" style={{ gridTemplateColumns: formatString }}>
							{data_OrderlistCols[orderlistIndex]?.cols?.filter((column: MainOrderListColumn) => column.type !== 'openStatus' || allowComplete).map((column: MainOrderListColumn, i: number) =>
								<span key={i}>
									{(() => {
										// in the last header column is the button for the table display switch
										if (i === (data_OrderlistCols[orderlistIndex]?.cols?.length - (allowComplete ? 1 : 2))) {
											return <button className="columnSelectButton" onClick={handleColumnSelectClick}>
												<img
													src={`${imagePathForCurrentTheme}columns-svgrepo-com.svg`}
													onError={(event: any) => { event.target.src = "/images/columns-svgrepo-com.svg"; event.onerror = null }}
													alt="x-ray" />
											</button>
										} else {
											switch (column.type) {
												case 'openStatus':
													return <>
														<span onClick={(evt) => { handleColumnClick(evt, i, column) }}>{t(column.header)}</span>
														{/* <div className="open-status" onClick={(evt) => { handleColumnClick(evt, i, column) }}>
															<span className='dot' />
														</div> */}
														{column.sortable && column.selected ?
															sortButtons(column.sortUp ?? false, i, column)
															: null}
													</>
												case 'date':
												case 'dateOnly':
													return <>
														<span onClick={(evt) => { handleColumnClick(evt, i, column) }}>{t(column.header)}</span>
														{column.sortable && column.selected ?
															sortButtons(column.sortUp ?? false, i, column)
															: null}
													</>
												case 'string':
													return <>
														<span onClick={(evt) => { handleColumnClick(evt, i, column) }}>{t(column.header)}</span>
														{column.sortable && column.selected ?
															sortButtons(column.sortUp ?? false, i, column)
															: null}
													</>
												case 'previewImages':
													return <>
														<span onClick={(evt) => { handleColumnClick(evt, i, column) }}>{column.header}</span>
														{column.sortable && column.selected ?
															sortButtons(column.sortUp ?? false, i, column)
															: null}
													</>
												case 'BurgerMenu':
													return <>
														<span onClick={(evt) => { handleColumnClick(evt, i, column) }}>{column.header}</span>
														{column.sortable && column.selected ?
															sortButtons(column.sortUp ?? false, i, column)
															: null}
													</>
												default:
													return null;
											}
										}
									})()}
								</span>
							)}
						</li>

					</ul>

				</div> : null}

			<div className="orders">
				<ul className="order-list">
					{isSuccess ? data?.content?.map((study: study, i: number) => <OrderListEntry study={study} key={i} />) : null}
				</ul>
			</div>
			<OrderInfoPanel default={{ x: (window.innerWidth - 450) / 20, y: (window.innerHeight - 450) / 2, width: 450, height: undefined }} orderId={currentOrderId ?? ''} />
			<CommonDialog onClose={handleExportDialogClose} title={'Image Export'} visible={isExportDialogVisible > 0} okText={t("export")} cancelText={t("cancel", { ns: 'common' })}
				default={{ x: (window.innerWidth - 450) / 20, y: (window.innerHeight - 700) / 2, width: 550, height: undefined }} childProps={{}}>
				<ExportDialog orderId={currentOrderId ?? ''} isCalledFromOrderList={true} />
			</CommonDialog>
			<CommonDialog onOk={undefined} keepOnOK={true} onClose={handleFinalizeStudyDialogClose} title={t("FinalizeStudyDialog:finalizeStudy")} visible={isFinalizeStudyDialogVisible > 0} okText={t("FinalizeStudyDialog:finalize")} cancelText={t("cancel", { ns: 'common' })}
				default={{ x: (window.innerWidth - 450) / 20, y: (window.innerHeight - 700) / 2, width: 550, height: undefined }} childProps={{}}>
				<FinalizeStudyDialog orderId={currentOrderId ?? ''} showWaitPACS={false} isCalledFromOrderList={true} />
			</CommonDialog>
		</>
	);
}

export default MainOrderList;
