import { useEffect, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { apiSlice, device, deviceSet, property, useGetDeviceWebThingQuery } from '../../apis/apiSlice';
import { AppDispatch, RootState } from '../../store';
import { selectCurrentDeviceProperties, selectDeviceActions, selectDeviceProperties, selectDeviceRunnerActive, selectDeviceRunnerError, selectedDeviceSetId, selectTriggerImageUrl } from './AcquisitionSlice';

import { useORTranslation } from '../Localization/ORLocalization';
import './DeviceWebThing2.scss';

import { getImagePathForCurrentTheme, getLayout, getSelectedWorkitem, selectCurrentOrder } from '../OrderList/OrdersSlice';
import DevicePropReadonly, { getStatusText } from './DevicePropReadonly';
import DeviceProp from './DeviceProp';
import { getTechniqueParameters, selectCurrentActionRef } from './GeneratorTechniqueParametersSlice';
import { selectProcedureSelectionVisibilityState, setProcedure } from '../Procedures/ProcedureSlice';
import { formatISO } from 'date-fns';
import { Constants } from '../../Constants';
import { getCurrentProtocolStepNumber, getDemoMode } from '../ImageDisplay/ImageDisplaySlice';
import ExposureParameters from './ExposureParameters';
import ExposureParameters2 from './ExposureParameters2';
import PatientSizeWeight from './PatientSizeWeight';
import DensityModel from './DensityModel';
import { getColorCode, getValueForProperty } from './Device';

interface DeviceWebThing2Props {
	device: device | deviceSet;
	deviceSetId: number;
	orderId: string;
	deviceSet: any;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const DeviceWebThing2 = (props: DeviceWebThing2Props) => {
	const dispatch = useAppDispatch();

	const { t } = useORTranslation(['Acquisition']);
	const propertyDefs: [string, property][] = JSON.parse(useAppSelector((state) => selectDeviceProperties(state, props.device.config.endpoint)));
	const actions: [string, any][] = JSON.parse(useAppSelector((state) => selectDeviceActions(state, props.device.config.endpoint)));
	const isDeviceRunnerActive: boolean = useAppSelector((state) => selectDeviceRunnerActive(state));
	const deviceRunnerError: string = useAppSelector((state) => selectDeviceRunnerError(state));
	const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
	const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
	const currentDeviceSetId: number = useAppSelector((state) => selectedDeviceSetId(state));
	const procedureSelectionVisibilityState: number = useAppSelector((state) => selectProcedureSelectionVisibilityState(state));
	const actionRef: string | undefined = useAppSelector((state) => selectCurrentActionRef(state));
	const currentProtocolSettingsStepNumber: number = useAppSelector((state) => getCurrentProtocolStepNumber(state, Constants.ACQUISITION_STEP_TYPE, 'scheduled', 'rejected'));
	const currentOrderId: string | undefined = useAppSelector((state) => selectCurrentOrder(state));
	const layout: any = useAppSelector((state) => getLayout(state));
	const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
	const currentPropertyValues: [string, any][] = JSON.parse(useAppSelector((state) => selectCurrentDeviceProperties(state, props.device.config.endpoint)));
	const triggerImageUrl: string | undefined = useAppSelector((state) => selectTriggerImageUrl(state, props.device?.config?.endpoint));
	const demoMode: boolean = useAppSelector((state) => getDemoMode(state));

	//const activeEventSubscribers: number[] = useAppSelector((state) => selectActiveEventSubscribers(state));

	const currentWorkitemRef = useRef<any>();
	currentWorkitemRef.current = currentWorkitem;

	const currentProtocolSettingsStepNumberRef = useRef<number>();
	currentProtocolSettingsStepNumberRef.current = currentProtocolSettingsStepNumber;

	let propertyOrder = { status: 1, battery: 2, connection: 3, calibrationGui: 4, active: -1, focus: 4, voltage: 5, exposure: 6, sensitivityFactor: -1, current: 7 };

	if (layout?.propertyOrder) {
		propertyOrder = layout?.propertyOrder;
	}

	useEffect(() => {
		if (!deviceRunnerError) {
			dispatch(apiSlice.util.invalidateTags([{ type: 'DeviceWebThing', id: props.device.config.endpoint }]));
		}
	}, [dispatch, deviceRunnerError]);


	const {
		data,
		isSuccess,
	}: { data: any, isSuccess: boolean } = useGetDeviceWebThingQuery({ endpoint: props.device.config.endpoint, category: props.device?.category, id: props.device?.id }, {
		refetchOnMountOrArgChange: false,
		skip: isDeviceRunnerActive === false || deviceRunnerError !== '',
	});

	useEffect(() => {
		if (isSuccess && props.device.category === 'GENERATOR' && data?.deviceWebThingProps?.eventSubscriptionActive) {
			if (procedureSelectionVisibilityState > 1 && props.deviceSetId === currentDeviceSetId) {
				const action_ref = currentWorkitem?.id + "_" + formatISO(new Date());
				dispatch({ type: "GeneratorTechniqueParameters/setCurrentActionRef", payload: action_ref });
				dispatch({ type: "GeneratorTechniqueParameters/setValueValidationStatus", payload: 1 });
				dispatch(getTechniqueParameters({
					voltage: currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.kv,
					exposure: (currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber === 0) ? currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.masWithoutAEC : currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.masWithAEC,
					generatorEndpoint: props.device.config.endpoint, action_ref
				}));
			}
		}
	}, [currentWorkitem?.id, currentWorkitem?.details?.procedureCode,
		currentDeviceSetId, isSuccess, data?.deviceWebThingProps?.eventSubscriptionActive, currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber]);


	useEffect(() => {

		//console.log(actionRef + " " + data?.deviceWebThingProps?.set_technique_parameters_result?.data?.action_ref);
		if (isSuccess && props.device.category === 'GENERATOR') {
			if (data?.deviceWebThingProps?.eventSubscriptionActive && data?.deviceWebThingProps?.set_technique_parameters_result?.data?.action_ref === actionRef) {
				dispatch({ type: "GeneratorTechniqueParameters/setCurrentActionRef", payload: '' });
				if (data?.deviceWebThingProps?.set_technique_parameters_result?.data?.success) {
					dispatch({ type: "GeneratorTechniqueParameters/setValueValidationStatus", payload: 2 });

					const currentProtocolStep = currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber];
					const currentExposure = currentProtocolStep.scheduled?.setup?.measuringChamber === 0 ?
						currentProtocolStep.scheduled.generator.masWithoutAEC : currentProtocolStep.scheduled.generator.masWithAEC;

					if (currentExposure !== data?.deviceWebThingProps?.exposure ||
						currentProtocolStep.scheduled.generator.kv !== data?.deviceWebThingProps?.voltage) {

						const newProtocolStep = structuredClone(currentProtocolStep);

						if (newProtocolStep?.scheduled?.generator) {
							if (newProtocolStep.scheduled?.setup?.measuringChamber === 0) {
								newProtocolStep.scheduled.generator.masWithoutAEC = data?.deviceWebThingProps?.exposure;
							} else {
								newProtocolStep.scheduled.generator.masWithAEC = data?.deviceWebThingProps?.exposure;
							}
							newProtocolStep.scheduled.generator.kv = data?.deviceWebThingProps?.voltage;

							//console.log(newProtocolStep);
							dispatch(apiSlice.endpoints.updateWorkitemProtocolStep.initiate(
								{
									workitemId: currentWorkitem?.id,
									stepNumber: currentProtocolSettingsStepNumber,
									body: {
										protocol: newProtocolStep
									}
								}
							));

							dispatch(
								apiSlice.util.updateQueryData('getStudyWithWorkitems', currentOrderId, (data: any) => {
									const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
									if (currentWorkitem?.id && workitemList && workitemList.includes(currentWorkitem?.id)) {
										const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitem?.id);
										if (tmpWorkitem?.data) {
											tmpWorkitem.data.protocol.steps[currentProtocolSettingsStepNumber] = newProtocolStep;
										}
									}
									return data;
								})
							);
						}
					}
				} else {
					dispatch({ type: "GeneratorTechniqueParameters/setValueValidationStatus", payload: 3 });
				}
			}
		}
	}, [isSuccess, data?.deviceWebThingProps, data?.deviceWebThingProps?.eventSubscriptionActive]);

	function getProperty(property: [string, property], i: number) {
		if (property[1].readOnly === true) {
			const set_technique_parameters = actions.find((action: any) => action[0] === 'set_technique_parameters');
			if (set_technique_parameters && set_technique_parameters[1].input?.properties[property[0]] && property[0] !== 'exposure_time' && property[0] !== 'current') {
				return <DeviceProp key={i.toString()} device={props.device} property={property} endpoint={props.device.config.endpoint} techniqueParameters={set_technique_parameters[1]} isfirstDefaultWidget={firstDefaultWidget(property) === i} />
			} else {
				return <DevicePropReadonly device={props.device} property={property} key={i.toString()} isfirstDefaultWidget={firstDefaultWidget(property) === i} />
			}
		} else {
			return <DeviceProp device={props.device} property={property} endpoint={props.device.config.endpoint} key={i.toString()} isfirstDefaultWidget={firstDefaultWidget(property) === i} />
		}
	}

	function isSpecialWidget(property: [string, property]): boolean {
		return ["battery", "connection", "status", "manufacturerTool", "calibrationGui", "pulseTime", "softwareTriggerType", "softwareTriggerState", "active", "focus"].includes(property[0]);
	}

	function firstDefaultWidget(property: [string, property]): number {
		let ret: number = 0;
		if (propertyDefs) {
			for (let i = 0; i < propertyDefs.filter((property: [string, property]) => propertyOrder[property[0] as keyof typeof propertyOrder] !== -1).sort(compareFn).length; i++) {
				if (!isSpecialWidget(propertyDefs.filter((property: [string, property]) => propertyOrder[property[0] as keyof typeof propertyOrder] !== -1).sort(compareFn)[i])) {
					ret = i;
					break;
				}
			}
		}
		return ret;
	}

	function compareFn(item1: [string, property], item2: [string, property]) {

		const orderItem1: number = propertyOrder[item1[0] as keyof typeof propertyOrder] ?? 999;

		const orderItem2: number = propertyOrder[item2[0] as keyof typeof propertyOrder] ?? 999;

		if (orderItem1 > orderItem2) {
			return 1;
		} else if (orderItem2 > orderItem1) {
			return -1;
		} else {
			return 0;
		}
	}

	const onResetClicked = async () => {
		await dispatch(setProcedure({ procedure: { procedureCode: currentWorkitemRef?.current?.details?.procedureCode, procedureName: currentWorkitemRef?.current?.details?.procedureName, projection: currentWorkitemRef?.current?.details?.projection }, orderId: props.orderId, }));
		const action_ref = currentWorkitem?.id + "_" + formatISO(new Date());
		//dispatch({ type: "GeneratorTechniqueParameters/setCurrentActionRef", payload: action_ref });
		dispatch(getTechniqueParameters({
			voltage: currentWorkitemRef?.current?.protocol?.steps[currentProtocolSettingsStepNumberRef.current ?? 0]?.scheduled?.generator?.kv,
			exposure: (currentWorkitemRef?.current?.protocol?.steps[currentProtocolSettingsStepNumberRef.current ?? 0]?.scheduled?.setup?.measuringChamber === 0) ? currentWorkitemRef?.current?.protocol?.steps[currentProtocolSettingsStepNumberRef.current ?? 0]?.scheduled?.generator?.masWithoutAEC : currentWorkitemRef?.current?.protocol?.steps[currentProtocolSettingsStepNumberRef.current ?? 0]?.scheduled?.generator?.masWithAEC,
			generatorEndpoint: props.device.config.endpoint, action_ref
		}));
	}

	const triggerImage = () => {
		if (demoMode) {
			dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: triggerImageUrl, body: { triggerImage: { input: {} } } }));
		}
	}

	return (
		<div className="deviveWebThingPanel">
			{isSuccess && data ?
				<div className="deviveWebThingWrapper" >
					<div className="deviveWebThing" onClick={(evt) => evt.stopPropagation()}>
						{props.device.category !== 'SET' ?
							<>
								<div className="deviveWebThingHeader">
									<h1>
										<label className="deviveWebThingHeaderLabel1">{`${t(props.device.category)}:`}&nbsp;</label>
										<label className="deviveWebThingHeaderLabel2">{`${props.device.title}`}</label>
									</h1>
									<div className="deviveWebThingHeaderButtons">
										{propertyDefs?.find((property: [string, property]) => property[0] === 'battery') !== undefined ?
											<div className="deviveProperties_header">
												{getProperty(propertyDefs?.find((property: [string, property]) => property[0] === 'battery')!, -1)}
											</div>
											: null}
										{propertyDefs?.find((property: [string, property]) => property[0] === 'connection') !== undefined ?
											<div className="deviveProperties_header">
												{getProperty(propertyDefs?.find((property: [string, property]) => property[0] === 'connection')!, -1)}
											</div>
											: null}
										<button className="settingsButton venaDefaultButton">
											<img
												src={`${imagePathForCurrentTheme}settings.svg`}
												onError={(event: any) => { event.target.src = "/images/settings.svg"; event.onerror = null }}
												alt="settings.svg" />
										</button>
									</div>

								</div>
								<hr className="hruleDeviceWebThing" />

							</>
							: null}

						<div className="deviveProperties">
							{props.device?.category === 'GENERATOR' ?
								<ExposureParameters2 orderId={props.orderId} deviceSet={props?.deviceSet} device={props.device} />
								: null}
							<div className="devivePropertiesPanel">
								{propertyDefs?.filter((property: [string, property]) => propertyOrder[property[0] as keyof typeof propertyOrder] !== -1).sort(compareFn)
									.map((property: [string, property], i: number) => (
										getProperty(property, i)
									))}
							</div>

							{props.device?.category === 'GENERATOR' ?
								<>
									<div className="patientSizeWeightDiv">
										<div className="hruleDeviceWebThing2" />
										<div className="patientSizeWeightPointsPanel">
											<div className="patientSizeWeightPoints">
												<PatientSizeWeight orderId={props.orderId} deviceSet={props?.deviceSet} device={props.device} />
												<DensityModel orderId={props.orderId} deviceSet={props?.deviceSet} device={props.device} />
											</div>
										</div>
									</div>
									<div className="statusMessageDivWrapper">
										<div className="statusMessageDiv">
											<div className="statusIcon">
												<svg width="12" height="22" viewBox="0 0 12 22" fill="none">
													<path d="M10.5273 3.11738L10.5273 9.4695C10.5273 9.4695 10.5273 11.5869 8.40997 11.5869L3.11653 11.5869C3.11653 11.5869 0.99915 11.5869 0.99915 9.4695L0.99915 3.11738C0.99915 3.11738 0.99915 1 3.11653 1L8.40997 1C8.40997 1 10.5273 1 10.5273 3.11738Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
													<path d="M3.64491 13.7045L2.44012 16.1148C2.39983 16.1955 2.38083 16.2851 2.38493 16.3753C2.38902 16.4654 2.41608 16.5529 2.46352 16.6296C2.51096 16.7064 2.57722 16.7697 2.65601 16.8136C2.7348 16.8575 2.82351 16.8806 2.91371 16.8806L8.61157 16.8806C8.70178 16.8806 8.79051 16.8576 8.86932 16.8137C8.94812 16.7698 9.01438 16.7064 9.06178 16.6297C9.10918 16.5529 9.13615 16.4653 9.14011 16.3752C9.14408 16.2851 9.12492 16.1954 9.08445 16.1148L7.87966 13.7045" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
													<path d="M7.87681 19.412L9.46484 21" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
													<path d="M3.64063 19.412L2.05259 21" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
													<path className="statusIndicator" id={getColorCode(props.device.category, getValueForProperty('status', currentPropertyValues)?.status_code).toString().toUpperCase()} d="M8.82422 4.13073L8.82422 8.30465C8.82422 8.30465 8.82422 9.69595 7.47156 9.69595L4.08992 9.69595C4.08992 9.69595 2.73726 9.69595 2.73726 8.30465L2.73726 4.13073C2.73726 4.13073 2.73726 2.73943 4.08992 2.73943L7.47156 2.73943C7.47156 2.73943 8.82422 2.73943 8.82422 4.13073Z" fill="#D76161" />
												</svg>
											</div>
											<div className="statusLabelWrapper">
												<label className="statusLabel">{getStatusText(props.device.category, currentPropertyValues)}</label>
											</div>
											<div className="resetButtonWrapper">
												<button className="resetButton" onClick={onResetClicked}>
													<img
														src={`${imagePathForCurrentTheme}reset.svg`}
														onError={(event: any) => { event.target.src = "/images/reset.svg"; event.onerror = null }}
														alt="reset.svg" />
												</button>
											</div>
										</div>
									</div>
								</>
								: null}


							{props.device?.category === 'DETECTOR' ?
								<div className="statusDivWrapper">
									<div className="statusDiv">
										<div className="statusIcon" onClick={() => triggerImage()}>
											<svg id={getColorCode(props.device.category, getValueForProperty('status', currentPropertyValues)?.status_code).toString().toUpperCase()}
												width="14" height="14" viewBox="0 0 14 14" >
												<g >
													<circle cx="7" cy="7" r="7" />
												</g>

											</svg>
										</div>
										<div className="statusLabelWrapper">
											<label className="statusLabel" id={getColorCode(props.device.category, getValueForProperty('status', currentPropertyValues)?.status_code).toString().toUpperCase()}>
												{getStatusText(props.device.category, currentPropertyValues)}</label>
										</div>

									</div>
								</div>
								: null}

							{props.device?.category === 'GENERATOR' ?
								<ExposureParameters orderId={props.orderId} deviceSet={props?.deviceSet} />
								: null}
						</div>

					</div>

				</div>
				:
				null
			}
		</div>
	);
}

export default DeviceWebThing2;
