import React from "react";

import { TypedUseSelectorHook, useDispatch, useSelector, } from "react-redux";
import { AppDispatch, RootState } from "../../store";


import "./PregnancyStatus.scss";
import { getSelectedSubject } from "./SubjectSlice";

type PregnancyStatusProps = {
    required?: boolean;
};

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const PregnancyStatus = (props: PregnancyStatusProps) => {

    const dispatch = useAppDispatch();

    const subject: any = useAppSelector((state) => getSelectedSubject(state));

    const onPregnancyClicked = (value: string) => {
        const newSubject: any = structuredClone(subject);
        const { details } = newSubject;

        newSubject.details = { ...details, pregnancyStatus: value };
        dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
    }

    return (
        <div className="Pregnancy">
            <label>
                <span>{`Pregnancy`}</span>
                <span className="required">&nbsp;{`${props.required ? '*' : ''}`}</span>
            </label>
            <div className="PregnancyButtons">
                <button className={`${subject?.details?.pregnancyStatus === 'definitely_pregnant' ? 'PregnancyButton_selected' : 'PregnancyButton'}  venaDefaultButton`} onClick={() => onPregnancyClicked('definitely_pregnant')} >{'Yes'}</button>
                <button className={`${subject?.details?.pregnancyStatus === 'not_pregnant' ? 'PregnancyButton_selected' : 'PregnancyButton'}  venaDefaultButton`} onClick={() => onPregnancyClicked('not_pregnant')} >{'No'}</button>
                <button className={`${subject?.details?.pregnancyStatus === 'possibly_pregnant' ? 'PregnancyButton_selected' : 'PregnancyButton'}  venaDefaultButton`} onClick={() => onPregnancyClicked('possibly_pregnant')} >{'Maybe'}</button>
                <button className={`${subject?.details?.pregnancyStatus === 'unknown' ? 'PregnancyButton_selected' : 'PregnancyButton'}  venaDefaultButton`} onClick={() => onPregnancyClicked('unknown')} >{'unknown'}</button>
            </div>
        </div>
    );
};

export default PregnancyStatus;
