import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
    useGetMacroByNamesPathQuery, useGetMacrosForFavoriteTagQuery, useGetProcedureByNamesPathQuery,
    useGetProceduresForFavoriteTagQuery, useRemoveProcedureFavoriteTagMutation, useRemoveMacroFavoriteTagMutation,
    useSetNewFavoriteForMacroIdMutation, useSetNewFavoriteForProcedureCodeMutation,
    apiSlice
} from '../../apis/apiSlice';
import './ProcedureList.scss';
import { AppDispatch, RootState } from '../../store';
import { getImagePathForCurrentTheme, selectedStudy } from '../OrderList/OrdersSlice';
import { selectFavouriteTag, selectedComponentSubtree, setMacro, setProcedure } from './ProcedureSlice';
import { macro } from '../../apis/apiSlice';
import React, { useRef } from 'react';

interface ProcedureListProps {
    orderId: string;
    showFavourites: boolean;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const ProcedureList = (props: ProcedureListProps) => {

    const dispatch = useAppDispatch();

    const currentComponentSubtree: string[] = useAppSelector((state) => selectedComponentSubtree(state));
    const selectedFavouriteTag: string = useAppSelector((state) => selectFavouriteTag(state));
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const currentStudyAsString: string | undefined = useAppSelector((state) => selectedStudy(state, props.orderId ?? ''));
    //const study = currentStudyAsString ? JSON.parse(currentStudyAsString) : undefined;

    const [setNewFavoriteForProcedureCode] = useSetNewFavoriteForProcedureCodeMutation();
    const [removeProcedureFavorite] = useRemoveProcedureFavoriteTagMutation();
    const [removeMacroFavorite] = useRemoveMacroFavoriteTagMutation();
    const [setNewFavoriteForMacroId] = useSetNewFavoriteForMacroIdMutation();

    const currentStudyAsStringRef = useRef<string | undefined>();
	currentStudyAsStringRef.current = currentStudyAsString;

    const {
        data,
        isSuccess,
    }: { data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } = useGetProcedureByNamesPathQuery(currentComponentSubtree, {
        refetchOnMountOrArgChange: true,
        skip: currentComponentSubtree === undefined || currentComponentSubtree?.length < 3,
    })

    const {
        data: data_macros,
        isSuccess: isSuccess_macros,
    }: { data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } = useGetMacroByNamesPathQuery(currentComponentSubtree, {
        refetchOnMountOrArgChange: true,
        skip: currentComponentSubtree === undefined || currentComponentSubtree?.length < 3,
    })


    const {
        data: data_favourites,
        isSuccess: isSuccess_favourites,
    }: { data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } = useGetProceduresForFavoriteTagQuery(selectedFavouriteTag, {
        refetchOnMountOrArgChange: true,
        skip: selectedFavouriteTag === undefined || selectedFavouriteTag === '',
    })

    const {
        data: data_favourites_macros,
        isSuccess: isSuccess_favourites_macros,
    }: { data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } = useGetMacrosForFavoriteTagQuery(selectedFavouriteTag, {
        refetchOnMountOrArgChange: true,
        skip: selectedFavouriteTag === undefined || selectedFavouriteTag === '',
    })

    const setSelectedMacro = async (macro: macro) => {
        dispatch(setMacro({ macro: macro, orderId: props.orderId, }));
    }

    const setSelectedProcedure = async (procedure: any) => {
        await dispatch(setProcedure({ procedure: procedure, orderId: props.orderId, }));
        const currentStudy = currentStudyAsStringRef.current ? JSON.parse(currentStudyAsStringRef.current) : undefined;
        dispatch(apiSlice.util.invalidateTags([{ type: 'RelatedStudies', id: currentStudy?.details?.subject?.registrationId}]));
    }

    const onMouseDown = (evt: React.MouseEvent<Element>, procedure: any, fromClickEvent: boolean = false) => {
        if (evt.button === 2 || fromClickEvent) {
            if (procedure?.procedureCode && selectedFavouriteTag) {
                if (data_favourites?.find((proc: any) => proc?.procedureCode === procedure?.procedureCode)) {
                    removeProcedureFavorite({ procedureCode: procedure?.procedureCode, "favoriteTag": selectedFavouriteTag })
                } else {
                    setNewFavoriteForProcedureCode({ procedureCode: procedure?.procedureCode, "favoriteTag": selectedFavouriteTag })
                }
            }
        }
    }

    const onMouseDownMacro = (evt: React.MouseEvent<Element>, macro: any, fromClickEvent: boolean = false) => {
        if (evt.button === 2 || fromClickEvent) {
            if (macro?.id && selectedFavouriteTag) {
                if (data_favourites_macros?.find((mac: any) => mac?.id === macro?.id)) {
                    removeMacroFavorite({ macroId: macro?.id, "favoriteTag": selectedFavouriteTag })
                } else {
                    setNewFavoriteForMacroId({ macroId: macro?.id, "favoriteTag": selectedFavouriteTag })
                }
            }
        }
    }

    const isProcedureFavorite = (procedure: any): boolean => {
        return data_favourites?.find((proc: any) => proc?.procedureCode === procedure?.procedureCode) !== undefined;
    }

    const isMacroFavorite = (macro: any): boolean => {
        return data_favourites_macros?.find((mac: any) => mac.id === macro?.id) !== undefined;
    }

    function getProcedureList(showFavourites: boolean, componentSubtreeLength: number, isSucessProcedureList: boolean, isSucessFavourites: boolean) {
        if (showFavourites) {
            if (isSucessFavourites) {
                return (
                    <div className="procedureWrapper">
                        <div className="procedureButtonList">
                            <>
                                {data_favourites_macros?.slice().sort((a: any, b: any) => ('' + a?.name).localeCompare(b?.name))?.map((macro: any, i: number) => (
                                    <button className="macroButton" key={i} onClick={() => setSelectedMacro(macro)} onMouseDown={(e) => onMouseDownMacro(e, macro)}>
                                        <img className={isMacroFavorite(macro) ? "favourite" : "nofavourite"}
                                            src={`${imagePathForCurrentTheme}${data_favourites_macros?.find((mac: any) => mac.id === macro?.id) ? 'favorite-svgrepo-com.svg' : 'nofavorite-svgrepo-com.svg'}`}
                                            onError={(event: any) => {
                                                event.target.src = `${isMacroFavorite(macro) ?
                                                    '/images/favorite-svgrepo-com.svg' : '/images/nofavorite-svgrepo-com.svg'}`; event.onerror = null
                                            }}
                                            alt="favorite-svgrepo-com.svg" onClick={(e) => { e.stopPropagation(); onMouseDownMacro(e, macro, true) }}
                                            title={isMacroFavorite(macro) ? 'remove form favorites' : 'add to favorites'} />
                                        <label className="labelName">{macro?.name}</label>
                                    </button>
                                ))}
                            </>
                            <>
                                {data_favourites?.slice().sort((a: any, b: any) => ('' + a?.procedureName).localeCompare(b?.procedureName))?.map((procedure: any, i: number) => (
                                    <button className="procedureButton" key={i} onClick={() => setSelectedProcedure(procedure)} onMouseDown={(e) => onMouseDown(e, procedure)}>
                                        <img className={isProcedureFavorite(procedure) ? "favourite" : "nofavourite"}
                                            src={`${imagePathForCurrentTheme}${isProcedureFavorite(procedure) ? 'favorite-svgrepo-com.svg' : 'nofavorite-svgrepo-com.svg'}`}
                                            onError={(event: any) => {
                                                event.target.src = `${isProcedureFavorite(procedure) ?
                                                    '/images/favorite-svgrepo-com.svg' : '/images/nofavorite-svgrepo-com.svg'}`; event.onerror = null
                                            }}
                                            alt="favorite-svgrepo-com.svg" onClick={(e) => { e.stopPropagation(); onMouseDown(e, procedure, true) }}
                                            title={isProcedureFavorite(procedure) ? 'remove form favorites' : 'add to favorites'} />
                                        <label className="labelName">{procedure?.procedureName}</label>
                                        <label className="labelProjection">{procedure?.projection}</label>
                                    </button>
                                ))}
                            </>
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        } else {
            if (componentSubtreeLength > 2 && isSucessProcedureList) {
                return (
                    <div className="procedureWrapper">
                        <div className="procedureButtonList">
                            <>
                                {data_macros?.slice().sort((a: any, b: any) => ('' + a?.name).localeCompare(b?.name))?.map((macro: any, i: number) => (
                                    <button className="macroButton" key={i} onClick={() => setSelectedMacro(macro)}>
                                        <img className={isMacroFavorite(macro) ? "favourite" : "nofavourite"}
                                            src={`${isMacroFavorite(macro) ? 'favorite-svgrepo-com.svg' : 'nofavorite-svgrepo-com.svg'}`}
                                            onError={(event: any) => {
                                                event.target.src = `${isMacroFavorite(macro) ?
                                                    '/images/favorite-svgrepo-com.svg' : '/images/nofavorite-svgrepo-com.svg'}`; event.onerror = null
                                            }}
                                            alt="favorite-svgrepo-com.svg" onClick={(e) => { e.stopPropagation(); onMouseDownMacro(e, macro, true) }}
                                            title={isMacroFavorite(macro) ? 'remove form favorites' : 'add to favorites'} />
                                        <label className="labelName">{macro?.name}</label>
                                    </button>
                                ))}
                            </>
                            <>
                                {data?.slice().sort((a: any, b: any) => ('' + a?.procedureName).localeCompare(b?.procedureName))?.map((procedure: any, i: number) => (
                                    <button className="procedureButton" key={i} onClick={() => setSelectedProcedure(procedure)}>
                                        <img className={data_favourites?.find((proc: any) => proc?.procedureCode === procedure?.procedureCode) ? "favourite" : "nofavourite"}
                                            src={`${imagePathForCurrentTheme}${isProcedureFavorite(procedure) ? 'favorite-svgrepo-com.svg' : 'nofavorite-svgrepo-com.svg'}`}
                                            onError={(event: any) => {
                                                event.target.src = `${isProcedureFavorite(procedure) ?
                                                    '/images/favorite-svgrepo-com.svg' : '/images/nofavorite-svgrepo-com.svg'}`; event.onerror = null
                                            }}
                                            alt="favorite-svgrepo-com.svg" onClick={(e) => { e.stopPropagation(); onMouseDown(e, procedure, true) }}
                                            title={isProcedureFavorite(procedure) ? 'remove form favorites' : 'add to favorites'} />
                                        <label className="labelName">{procedure?.procedureName}</label>
                                        <label className="labelProjection">{procedure?.projection}</label>
                                    </button>
                                ))}
                            </>
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        }
    }


    return (
        <>
            {getProcedureList(props.showFavourites, currentComponentSubtree?.length, isSuccess, isSuccess_favourites)}
        </>
    );
}

export default ProcedureList;
