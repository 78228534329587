import { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import './ExposureParameters.scss';
import AECButton from './AECButton';
import { getImagePathForCurrentTheme, getSelectedWorkitem } from '../OrderList/OrdersSlice';
import { apiSlice } from '../../apis/apiSlice';
import { getTechniqueParameters } from './GeneratorTechniqueParametersSlice';
import { selectGeneratorForDeviceSet } from './AcquisitionSlice';
import { formatISO } from 'date-fns';
import { Constants } from '../../Constants';
import { getCurrentProtocolStepNumber } from '../ImageDisplay/ImageDisplaySlice';

interface ExposureParametersProps {
    orderId: string;
    deviceSet: any;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const ExposureParameters = (props: ExposureParametersProps) => {

    const dispatch = useAppDispatch();

    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
    const generatorForDeviceSet: any = useAppSelector((state) => selectGeneratorForDeviceSet(state, props.deviceSet?.id));
    const currentProtocolSettingsStepNumber: number = useAppSelector((state) => getCurrentProtocolStepNumber(state, Constants.ACQUISITION_STEP_TYPE, 'scheduled', 'rejected'));


    const [selectedSizeIndex, setSelectedSizeIndex] = useState<number>(0);

    const handleGridClick = (evt: React.MouseEvent<Element>) => {
        const currentProtocolStep = currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber];
        const newProtocolStep = structuredClone(currentProtocolStep);

        if (currentProtocolStep?.scheduled?.setup) {
            console.log(currentProtocolStep?.scheduled?.setup);
            newProtocolStep.scheduled.setup.grid = currentProtocolStep?.scheduled?.setup?.grid === 1 ? 0 : 1;

            const action_ref = currentWorkitem?.id + "_" + formatISO(new Date());
            const gridFactor = currentProtocolStep?.scheduled?.setup?.grid === 1 ? 2 : 0.5;
            if (newProtocolStep?.scheduled?.generator) {
                newProtocolStep.scheduled.generator.masWithoutAEC = newProtocolStep?.scheduled?.generator.masWithoutAEC * gridFactor;
                newProtocolStep.scheduled.generator.masWithAEC = newProtocolStep?.scheduled?.generator.masWithAEC * gridFactor;
            }
            dispatch(getTechniqueParameters({
                voltage: currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.kv,
                exposure: (currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber === 0) ? currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.masWithoutAEC * gridFactor : currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.masWithAEC * gridFactor,
                generatorEndpoint: generatorForDeviceSet?.config?.endpoint, action_ref
            }));

            dispatch(apiSlice.endpoints.updateWorkitemProtocolStep.initiate(
                {
                    workitemId: currentWorkitem?.id,
                    stepNumber: currentProtocolSettingsStepNumber,
                    body: {
                        protocol: newProtocolStep
                    }
                }
            ));

            dispatch(
                apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                    const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                    if (currentWorkitem?.id && workitemList && workitemList.includes(currentWorkitem?.id)) {
                        const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitem?.id);
                        if (tmpWorkitem?.data) {
                            tmpWorkitem.data.protocol.steps[currentProtocolSettingsStepNumber] = newProtocolStep;
                        }
                    }
                    return data;
                })
            );
        }
    }

    const handlePatientSizeClick = (sizeValue: any) => {
        setSelectedSizeIndex(sizeValue?.sizeIndex);
        if (generatorForDeviceSet?.config?.endpoint) {
            const action_ref = currentWorkitem?.id + "_" + formatISO(new Date());
            dispatch(getTechniqueParameters({
                voltage: sizeValue?.kv,
                exposure: (currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber === 0) ? sizeValue.masWithoutAEC : sizeValue.masWithAEC,
                generatorEndpoint: generatorForDeviceSet?.config?.endpoint, action_ref
            }));
        }
    }

    useEffect(() => {
        setSelectedSizeIndex(0);
    }, [currentWorkitem?.id, currentWorkitem?.details?.procedureCode,
    props.deviceSet?.id]);

    return (
        <div className="exposureParameters">
            <div className="exposureParameters_AEC_Wrapper">
                <div className="exposureParameters_AEC">
                    <div className="AECButtonWrapper">
                        <AECButton measuringChamber={0} orderId={props.orderId} deviceSet={props.deviceSet} />
                        <AECButton measuringChamber={1} orderId={props.orderId} deviceSet={props.deviceSet} />
                        <AECButton measuringChamber={2} orderId={props.orderId} deviceSet={props.deviceSet} />
                        <AECButton measuringChamber={4} orderId={props.orderId} deviceSet={props.deviceSet} />
                        <AECButton measuringChamber={3} orderId={props.orderId} deviceSet={props.deviceSet} />
                    </div>
                    <div className="grid">
                        <button onClick={handleGridClick} className={currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.grid === 0 ? "gridButton_selected" : "gridButton"}>
                            <img className="gridButtonImg"
                                src={`${imagePathForCurrentTheme}layout-4lines-svgrepo-com.svg`}
                                onError={(event: any) => { event.target.src = "/images/layout-4lines-svgrepo-com.svg"; event.onerror = null }}
                                alt="tag browser" />
                        </button>
                    </div>
                </div>
            </div>
            {currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.patientSizeValues ?
                <div className="patientSizeValues">
                    <div className="patientSizeValuesButtonList">
                        {[...currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.patientSizeValues,
                        {
                            kv: currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.kv,
                            masWithoutAEC: currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.masWithoutAEC,
                            masWithAEC: currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.masWithAEC,
                            sizeIndex: 0
                        }]?.sort((sizeValue1: any, sizeValue2: any) => sizeValue1?.sizeIndex - sizeValue2?.sizeIndex)?.map((sizeValue: any, i: number) => (
                            <button onClick={() => handlePatientSizeClick(sizeValue)} className={sizeValue?.sizeIndex === selectedSizeIndex ? "patientSizeValuesButton_selected" : "patientSizeValuesButton"} key={i.toString()}>
                                <img className="patientSizeValuesImg" style={{ transform: `scale(${1 + (0.22 * sizeValue?.sizeIndex)}, 1.0)` }}
                                    src={`${imagePathForCurrentTheme}human-boy-person-man-svgrepo-com.svg`}
                                    onError={(event: any) => { event.target.src = "/images/human-boy-person-man-svgrepo-com.svg"; event.onerror = null }}
                                    alt="tag browser" />
                            </button>
                        ))}
                    </div>
                </div> : null}
        </div>
    );
}

export default ExposureParameters;
