import React, { useState } from "react";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useORTranslation } from "../Localization/ORLocalization";
import { study, useSearchRelatedStudiesQuery } from "../../apis/apiSlice";
import "./RelatedStudies.scss";
import RelatedStudiesRow from "./RelatedStudiesRow";
import { getImagePathForCurrentTheme, getLayout, selectIsRelatedStudiesOpen } from "../OrderList/OrdersSlice";

type RelatedStudiesProps = {
    study: any;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const RelatedStudies = (props: RelatedStudiesProps) => {

    const { t } = useORTranslation(['common']);

    const dispatch = useAppDispatch();

    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const [showAllRelatedStudies, setShowAllRelatedStudies] = useState(false);
    const layout: any = useAppSelector((state) => getLayout(state));
    const isRelatedStudiesOpen: boolean = useAppSelector((state) => selectIsRelatedStudiesOpen(state));



    const maxRelatedStudies = layout?.maxRelatedStudies ?? 4;

    const {
        data,
        isSuccess,
    }: { data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } =
        useSearchRelatedStudiesQuery(props.study?.details?.subject?.registrationId, {
            refetchOnMountOrArgChange: true,
            skip: props.study?.details?.subject?.registrationId === undefined,
        })

    const overflow = Math.max((data?.content?.filter((study: any, i: number) => study?.id !== props.study?.id)?.length || 0) - maxRelatedStudies, 0);

    return (
        <>
            {isSuccess && data?.content?.filter((study: any, i: number) => study?.id !== props.study?.id).length > 0 ?
                <fieldset className={"fieldsetRelatedStudies"}>
                    <legend>Related Studies</legend>
                    <div className="RelatedStudiesWrapper">
                        <>
                            <div className="relatedStudies-DropDown" onClick={(evt: any) =>  dispatch({ type: "Orders/setIsRelatedStudiesOpen", payload: !isRelatedStudiesOpen })}>
                                <label>Related Studies</label>
                                <img className="menuOpenArrowImg"
                                    src={isRelatedStudiesOpen ? `${imagePathForCurrentTheme}Pfeil_nach_oben.svg` : `${imagePathForCurrentTheme}Pfeil_nach_unten.svg`}
                                    onError={(event: any) => { event.target.src = isRelatedStudiesOpen ? "/images/Pfeil_nach_oben.svg" : "/images/Pfeil_nach_unten.svg"; event.onerror = null }}
                                    alt={"Pfeil nach oben.svg"}
                                />
                            </div>
                            <div className={isRelatedStudiesOpen ? "relatedStudies" : "relatedStudies_invisible"}>
                                {isSuccess ? data?.content?.filter((study: any, i: number) => study?.id !== props.study?.id)
                                    ?.sort((a: study, b: study) => ('' + b?.details?.opened).localeCompare(a?.details?.opened))
                                    ?.slice(0, showAllRelatedStudies ? data?.content?.filter((study: any, i: number) => study?.id !== props.study?.id)?.length : maxRelatedStudies)
                                    ?.map((study: any, i: number) =>
                                        <RelatedStudiesRow study={study} key={i.toString()} />
                                    )
                                    : null}
                            </div>
                            {data?.content?.filter((study: any, i: number) => study?.id !== props.study?.id)?.length > maxRelatedStudies ?
                                <>
                                    <hr className="hrule" />
                                    {showAllRelatedStudies ?
                                        <img className="collapse-up" onClick={(evt: any) => setShowAllRelatedStudies(false)}
                                            src={`${imagePathForCurrentTheme}collapse-up-svgrepo-com.svg`}
                                            onError={(event: any) => { event.target.src = "/images//collapse-up-svgrepo-com.svg"; event.onerror = null }}
                                            alt="collapse-up" />
                                        :
                                        <span className="relatedStudies-more" onClick={(evt: any) => setShowAllRelatedStudies(true)}>
                                            {overflow ? `+ ${overflow} ${t('more ...', { count: overflow })}` : ''}
                                        </span>
                                    }

                                </>
                                :
                                null}
                        </>
                    </div>
                </fieldset>
                : null}
        </>
    );
};

export default RelatedStudies;
