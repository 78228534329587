import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { RootState } from "../../store";
import { selectIsAcquistionOngoing } from "../Akquisition/AcquisitionSlice";
import { getExportActive, getMatrixIndexForInsert, selectMatrixIndex, getIsImageProcessing } from "./ImageDisplaySlice";
import Spinner from "./Spinner";

interface LoadingIndicatorProps {
  index: number;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const imageLoadStatus = useAppSelector((state) => state.Orders.imageLoadStatus);
  const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
  const isAkquisitionButtonActive: boolean = useAppSelector((state) => state.Acquisition.isAcquistionButtonActive) ?? false;
  const isAcquistionOngoing: boolean = useAppSelector((state) => selectIsAcquistionOngoing(state)) ?? false;
  const matrixIndexForInsert: number = useAppSelector((state) => getMatrixIndexForInsert(state, -1));
  const isExportActive: boolean = useAppSelector((state) => getExportActive(state));
  const isImageProcessing: boolean = useAppSelector((state) => getIsImageProcessing(state));

  const [isLoadingIndicatorVisible, setIsLoadingIndicatorVisible] = useState<boolean | undefined>(false);

  useEffect(() => {

    /* console.log("imageLoadStatus: " + imageLoadStatus + " selectedMatrixIndex: " + selectedMatrixIndex + " matrixIndexForInsert: " + matrixIndexForInsert + " props.index: " +
      props.index + " isAkquisitionButtonActive: " + isAkquisitionButtonActive + " isAcquistionOngoing: " + isAcquistionOngoing + " isExportActive: " + isExportActive + "   " +
      (props.index === -1 || (imageLoadStatus === "loading" && selectedMatrixIndex === props.index)) + " " +
      (isAkquisitionButtonActive && isAcquistionOngoing && selectedMatrixIndex === props.index)); */


    /* if (isAkquisitionButtonActive) {
      setIsLoadingIndicatorVisible((props.index === -1 ||
        (imageLoadStatus === "loading" && selectedMatrixIndex === props.index)) ||
        (isAkquisitionButtonActive && isAcquistionOngoing && selectedMatrixIndex === props.index));
    } else {
      setIsLoadingIndicatorVisible((props.index === -1 ||
        (imageLoadStatus === "loading" && matrixIndexForInsert === props.index)) ||
        (isAkquisitionButtonActive && isAcquistionOngoing && matrixIndexForInsert === props.index));
    } */

    setIsLoadingIndicatorVisible((props.index === -1 ||
      (imageLoadStatus === "loading" && selectedMatrixIndex === props.index && !isExportActive)) ||
      (isAkquisitionButtonActive && isAcquistionOngoing && selectedMatrixIndex === props.index) || isImageProcessing);



  }, [props.index, imageLoadStatus, selectedMatrixIndex, matrixIndexForInsert, isAkquisitionButtonActive, isAcquistionOngoing, isExportActive, isImageProcessing]);

  const spinner = () => (
    <React.Fragment>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" width="200px" height="200px" viewBox="0 0 200 200" enableBackground="new 0 0 200 200" xmlSpace="preserve">
        <g>
          <path
            fill="none"
            stroke="hsla(196, 30%, 38%, 1.0)"
            strokeWidth="3"
            strokeMiterlimit="10"
            d="M138.768,100c0,21.411-17.356,38.768-38.768,38.768
		c-21.411,0-38.768-17.356-38.768-38.768c0-21.411,17.357-38.768,38.768-38.768"
          />
          <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 100 100" to="360 100 100" dur="8s" repeatCount="indefinite" />
        </g>
        <g>
          <path
            fill="none"
            stroke="hsla(195, 17%, 40%, 1.0)"
            strokeWidth="3"
            strokeMiterlimit="10"
            d="M132.605,100c0,18.008-14.598,32.605-32.605,32.605
		c-18.007,0-32.605-14.598-32.605-32.605c0-18.007,14.598-32.605,32.605-32.605"
          />
          <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 100 100" to="360 100 100" dur="4s" repeatCount="indefinite" />
        </g>
        <g>
          <path
            fill="none"
            stroke="hsl(196, 26%, 32%)"
            strokeWidth="3"
            strokeMiterlimit="10"
            d="M126.502,100c0,14.638-11.864,26.502-26.502,26.502
		c-14.636,0-26.501-11.864-26.501-26.502c0-14.636,11.865-26.501,26.501-26.501"
          />
          <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 100 100" to="360 100 100" dur="2s" repeatCount="indefinite" />
        </g>
        <g>
          <path
            fill="none"
            stroke="hsl(195, 68%, 16%)"
            strokeWidth="3"
            strokeMiterlimit="10"
            d="M120.494,100c0,11.32-9.174,20.494-20.494,20.494
		c-11.319,0-20.495-9.174-20.495-20.494c0-11.319,9.176-20.495,20.495-20.495"
          />
          <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 100 100" to="360 100 100" dur="1s" repeatCount="indefinite" />
        </g>
      </svg>
    </React.Fragment>
  );

  return <div className="loadingIndicator">{
    isLoadingIndicatorVisible ?
    <Spinner className="Spinner"/>
      : null}</div>;
};

export default LoadingIndicator;
