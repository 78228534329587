import React, { useCallback, useEffect, useRef, useState } from "react";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getSelectedWorkitem, isSelectedWorkitemPlanning, selectArtifactForSelectedWorkitem, selectArtifactIdForSelectedWorkitem, selectInstanceForWorkitem, selectSeriesDataForWorkitem, selectedStudy } from "../OrderList/OrdersSlice";
import { useORTranslation } from "../Localization/ORLocalization";
import "./ProcedureDataEdit.scss";

import { getSeriesForProtocolStep, selectedWorkitem, selectProcedureEditVisible } from "../ImageDisplay/ImageDisplaySlice";

import InfoPanel from "../Utils/ConfigurableInfoPanel/InfoPanel";
import { apiSlice } from "../../apis/apiSlice";


type ProcedureDataEditProps = {
    orderId: string;
    refresh?: number;
    resetRefresh?: () => void;
    onActionDone?: () => void;

};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

export const getAge = (date: Date) => {
    var today = new Date();
    var birthDate = date;
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

const ProcedureDataEdit = (props: ProcedureDataEditProps) => {

    const { t } = useORTranslation(['Subject', 'common']);

    const dispatch = useAppDispatch();

    const currentStudyAsString: string | undefined = useAppSelector((state) => selectedStudy(state, props.orderId ?? ''));
    const study = currentStudyAsString ? JSON.parse(currentStudyAsString) : undefined;

    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
    const currentSeriesAsString: string | undefined = useAppSelector((state) => selectSeriesDataForWorkitem(state, currentWorkitemId));
    const currentSeries = currentSeriesAsString ? JSON.parse(currentSeriesAsString) : undefined;
    const currentInstanceAsString: string | undefined = useAppSelector((state) => selectInstanceForWorkitem(state, currentWorkitemId));
    const currentInstance = currentInstanceAsString ? JSON.parse(currentInstanceAsString) : undefined;
    const currentArtifactAsString: string | undefined = useAppSelector((state) => selectArtifactForSelectedWorkitem(state));
    const currentArtifact = currentArtifactAsString ? JSON.parse(currentArtifactAsString) : undefined;
    const currentArtifactId: string | undefined = useAppSelector((state) => selectArtifactIdForSelectedWorkitem(state));
    const isProcedureEditVisible: number = useAppSelector((state) => selectProcedureEditVisible(state));
    const isWorkitemPlanning: boolean = useAppSelector((state) => isSelectedWorkitemPlanning(state));

    const seriesForProtocolStepAsString: string = useAppSelector((state) => getSeriesForProtocolStep(state, currentWorkitem, isProcedureEditVisible));
    const seriesForProtocolStep: any = seriesForProtocolStepAsString ? JSON.parse(seriesForProtocolStepAsString) : undefined;

    const [workitemDetails, setWorkitemDetails] = useState<any>(undefined);
    const [currentProtocolStep, setCurrentProtocolStep] = useState<any>(undefined);
    const [currentRawSeries, setCurrentRawSeries] = useState<any>(undefined);
    const [newProtocolStep, setNewProtocolStep] = useState<any>(undefined);


    const newProtocolStepRef = useRef<any>();
    newProtocolStepRef.current = newProtocolStep;
    const workitemDetailsRef = useRef<any>();
    workitemDetailsRef.current = workitemDetails;
    const currentRawSeriesRef = useRef<any>();
    currentRawSeriesRef.current = currentRawSeries;

    const setUpdateObject = useCallback((arg: any, item?: any) => {
        if (arg?.details) {
            if (item.source === 'workitem') {
                const olddetails = structuredClone(workitemDetailsRef.current?.details);
                setWorkitemDetails({ details: { ...olddetails, ...arg?.details } });
            } else {
                const olddetails = structuredClone(currentRawSeriesRef.current?.details);
                setCurrentRawSeries({ details: { ...olddetails, ...arg?.details } });
            }
        } else {
            if (newProtocolStepRef.current) {
                if (arg?.scheduled) {
                    if (newProtocolStepRef.current?.scheduled?.generator) {
                        const newProtocolStepTmp = structuredClone(newProtocolStepRef.current);
                        newProtocolStepTmp.scheduled.generator = { ...newProtocolStepTmp.scheduled.generator, ...arg.scheduled?.generator };
                        setNewProtocolStep(newProtocolStepTmp);
                    }
                } else if (arg?.rejected) {
                    const newProtocolStepTmp = structuredClone(newProtocolStepRef.current);
                    newProtocolStepTmp.rejected = arg.rejected;
                    setNewProtocolStep(newProtocolStepTmp);
                } else if (arg?.mAs) {
                    if (newProtocolStepRef.current?.scheduled?.generator) {
                        const newProtocolStepTmp = structuredClone(newProtocolStepRef.current);
                        if (newProtocolStepRef.current?.scheduled?.setup?.measuringChamber === 0) {
                            newProtocolStepTmp.scheduled.generator.masWithoutAEC = arg?.mAs;
                        } else {
                            newProtocolStepTmp.scheduled.generator.masWithAEC = arg?.mAs;
                        }
                        setNewProtocolStep(newProtocolStepTmp);
                    }
                }
            }
        }
    }, [])

    useEffect(() => {
        const workitemDetails = structuredClone(currentWorkitem);
        setWorkitemDetails(workitemDetails);
        if (seriesForProtocolStep && Array.isArray(seriesForProtocolStep) && seriesForProtocolStep.length > 0) {
            const rawSeries = structuredClone(seriesForProtocolStep[0]);
            setCurrentRawSeries(rawSeries);
        }
        const actualProtocolStep = currentWorkitem?.protocol?.steps[isProcedureEditVisible];
        setCurrentProtocolStep(actualProtocolStep);
        const newProtocolStepTmp = structuredClone(actualProtocolStep);
        setNewProtocolStep(newProtocolStepTmp);

    }, [currentWorkitemAsString, isProcedureEditVisible]);

    useEffect(() => {
        if (props.refresh && props.refresh > 0) {
            dispatch(apiSlice.endpoints.updateWorkitemDetails1.initiate(
                {
                    workitemId: currentWorkitem?.id,
                    body: {
                        details: workitemDetails?.details,
                    }
                }
            ));



            dispatch(
                apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                    const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                    if (currentWorkitem?.id && workitemList && workitemList.includes(currentWorkitem?.id)) {
                        const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitem?.id);
                        if (tmpWorkitem?.data) {
                            tmpWorkitem.data.details = workitemDetails?.details;
                        }
                    }
                    return data;
                })
            );


            dispatch(apiSlice.endpoints.updateWorkitemProtocolStep.initiate(
                {
                    workitemId: currentWorkitem?.id,
                    stepNumber: isProcedureEditVisible,
                    body: {
                        protocol: newProtocolStep
                    }
                }
            ));

            dispatch(
                apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                    const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                    if (currentWorkitem?.id && workitemList && workitemList.includes(currentWorkitem?.id)) {
                        const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitem?.id);
                        if (tmpWorkitem?.data) {
                            tmpWorkitem.data.protocol.steps[isProcedureEditVisible] = newProtocolStep;
                        }
                    }
                    return data;
                })
            );

            if (currentRawSeries && Array.isArray(seriesForProtocolStep) && seriesForProtocolStep.length > 0) {
                dispatch(apiSlice.endpoints.updateSeriesDetails.initiate(
                    {
                        seriesId: seriesForProtocolStep[0]?.id,
                        body: currentRawSeries?.details,
                    }
                ));
            }
        }
        return () => {
            if (props.resetRefresh) {
                props.resetRefresh();
            }
            if (props.onActionDone) {
                props.onActionDone();
            }
        };
    }, [props.refresh]);



    return (
        <div className="EditProcedurePanelWrapper" onContextMenu={(e) => e.preventDefault()}>
            <div className="procedureEdit">
                <div className="procedure">
                    <InfoPanel className="infoPanelWrapper_Procedure" configFile={"/ProcedureEdit.json"}
                        configFileSection={currentWorkitem?.protocol?.steps[isProcedureEditVisible]?.rejected ? "Rejected" : (isWorkitemPlanning ? "Planning" : "NotRejected")}
                        workitemId={currentWorkitemId} artifactId={currentArtifactId} orderId={props.orderId ?? ''}
                        sources={{
                            currentStudy: study, currentWorkitem, currentSeries,
                            currentRawSeries: (seriesForProtocolStep && Array.isArray(seriesForProtocolStep) && seriesForProtocolStep.length > 0) ? seriesForProtocolStep[0] : undefined, currentInstance, currentArtifact,
                            subject: undefined, protocolStep: currentProtocolStep
                        }}
                        editMode={false} editEnabled={true} setUpdateObject={setUpdateObject} />
                </div>
            </div>
        </div>
    );
};

export default ProcedureDataEdit;
