import { useState } from 'react';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { device, useGetDeviceRegistryQuery } from '../../apis/apiSlice';
import { RootState } from '../../store';
import BatteryLevel from '../Utils/BatteryLevel';
import { selectCurrentDeviceProperties, selectDeviceCount, selectDeviceRunnerPath } from './AcquisitionSlice';
import DeviceSettingsButton, { DeviceType } from './DeviceSettingsButton';
import DeviceWebThing from './DeviceWebThing';

const Styles = {
	GeneratorSettings_Closed: { display: 'none' },
	GeneratorSettings_Open: { display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'nowrap' },
} as const

export enum SensorState {
	Unknown,
	AcqStart,
	Preparing,
	SensorReadyForAcq,
	WarmupStart,
	SensorReadyForPulse,
	Acquisition,
	ImageReady,
	AcqStop,
	Error,
	Disconnected
}

export type StateMapItem = {
	text: string,
	description: string,
	colorCode: number,
}

export const SensorStateMap = new Map<number, StateMapItem>([
	[0, { text: "Unknown", description: "Unknown", colorCode: 0 }],
	[1, { text: "AcqStart", description: "Acquisition Start", colorCode: 1 }],
	[2, { text: "Preparing", description: "Preparing", colorCode: 1 }],
	[3, { text: "Ready", description: "Sensor Ready for Acquisition", colorCode: 2 }],
	[4, { text: "Start", description: "Warmup Start", colorCode: 1 }],
	[5, { text: "Ready", description: "Sensor Ready for Pulse", colorCode: 2 }],
	[6, { text: "Acquisition", description: "Acquisition", colorCode: 1 }],
	[7, { text: "ImageReady", description: "Image Ready", colorCode: 1 }],
	[8, { text: "AcqStop", description: "Acquisition Stop", colorCode: 1 }],
	[9, { text: "Error", description: "Error", colorCode: 3 }],
	[10, { text: "Disconnected", description: "Disconnected", colorCode: 3 }],
	[0x10, { text: "Error", description: "Error Init", colorCode: 3 }],
	[0x11, { text: "Error", description: "Error not Calibrated", colorCode: 3 }],
	[0x12, { text: "Error", description: "Error Wifi", colorCode: 3 }],
	[0x13, { text: "Error", description: "Error Battery low", colorCode: 3 }],
	[0x14, { text: "Error", description: "Error Wrong Position", colorCode: 3 }],
	[0x15, { text: "Error", description: "Error High Temperatur", colorCode: 3 }],
	[0x16, { text: "Error", description: "Error License", colorCode: 3 }],
	[0x17, { text: "Error", description: "Error Image Corrupted", colorCode: 3 }],
	[0x18, { text: "Error", description: "Error Battery low", colorCode: 3 }],
	[0x19, { text: "Error", description: "Error Image Acquisition", colorCode: 3 }],
	[0x20, { text: "Error", description: "Error Image Processing", colorCode: 3 }],
	[0x23, { text: "Error", description: "Error Wrong Status", colorCode: 3 }],
	[0x26, { text: "Error", description: "Error IO Port", colorCode: 3 }],
	[0x303, { text: "Sleeping", description: "Sleeping", colorCode: 1 }],
]);


export const GeneratorStateMap = new Map<number, StateMapItem>([
	[0, { text: "Preparing", description: "Preparing", colorCode: 1 }],
	[1, { text: "PreparingEnd", description: "Preparing End", colorCode: 1 }],
	[2, { text: "Ready", description: "Ready", colorCode: 2 }],
	[3, { text: "Exposure", description: "Exposure", colorCode: 1 }],
	[4, { text: "ExposureEnd", description: "Exposure End", colorCode: 1 }],
	[5, { text: "Error", description: "Error", colorCode: 3 }],
	[6, { text: "Disconnected", description: "Disconnected", colorCode: 3 }],
]);



interface DeviceProps {
	deviceId: number;
	colorCode: number;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const getValueForProperty = (propertyName: string, propertyValues: [string, any][] | undefined) => {
	let ret: any = undefined;
	if (propertyValues && Array.isArray(propertyValues)) {
		const currentProperty: [string, Object] | undefined = propertyValues.find(elem => elem[0] === propertyName);
		if (currentProperty && Array.isArray(currentProperty) && currentProperty.length >= 2) {
			ret = currentProperty[1];
		}
	}
	return ret;
}

export const getColorCode = (category: string, state: number) => {
	if (category === "DETECTOR") {
		return SensorStateMap.get(state)?.colorCode ?? 0;
	} else if (category === "GENERATOR") {
		return GeneratorStateMap.get(state)?.colorCode ?? 0;
	}
	else {
		return 0;
	}
}

const Device = (props: DeviceProps) => {

	const deviceRunnerPath: string = useAppSelector((state) => selectDeviceRunnerPath(state));

	const {
		data,
		isSuccess,
	}: { data: device, isSuccess: boolean } = useGetDeviceRegistryQuery({ deviceId: props.deviceId, deviceRunnerPath: deviceRunnerPath }, {
		refetchOnMountOrArgChange: false,
	});


	const [generatorSettingsOpen, setGeneratorSettingsOpen] = useState<boolean>(false);
	const propertyValues: [string, any][]  = JSON.parse(useAppSelector((state) => selectCurrentDeviceProperties(state, data?.config?.endpoint)));
	const deviceCount: number = useAppSelector((state) => selectDeviceCount(state));

	const handleGenratorSettingsOpen = (evt: React.MouseEvent<Element>) => {
		evt.stopPropagation();
		setGeneratorSettingsOpen(!generatorSettingsOpen);
	}

	return (
		<>
			{isSuccess && data?.config?.endpoint ?
				<div className={deviceCount > 1 ? "hexagonInset" : "hexagonInset_b"}>
					<div style={generatorSettingsOpen ? Styles.GeneratorSettings_Open : Styles.GeneratorSettings_Closed}>
						<DeviceWebThing device={data} colorCode={props.colorCode}/>
					</div>
					<div className="hexagonInsetDiv">
						<div className="hexagonInsetInfoButton"></div>
						<DeviceSettingsButton type={data?.category ? (data?.category === "DETECTOR" ? DeviceType.Sensor : DeviceType.Generator) : DeviceType.Object} onClick={handleGenratorSettingsOpen}
							colorCode={getColorCode(data?.category, getValueForProperty('status', propertyValues)?.status_code)} icon='' />
						<div className="hexagonInsetInfo">
							{propertyValues?.find(p => p[0] === 'battery') ?
								<BatteryLevel colorSettingNr={0} batteryLevel={getValueForProperty('battery', propertyValues)?.level ?? 0} />
								: null}
						</div>
					</div>
				</div>
				: null}
		</>
	);
}

export default Device;
