import React from "react";
import './PreviewPanelInfo.scss';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { selectCountImages, selectCountPlannings } from "../OrderList/OrdersSlice";
import { useORTranslation } from "../Localization/ORLocalization";
import { setAcquisitionActive } from "../Akquisition/AcquisitionSlice";
import { useLocation, useNavigate } from "react-router-dom";



interface PreviewPanelInfoProps {
    orderId: string;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const PreviewPanelInfo = (props: PreviewPanelInfoProps) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate()

    const { t } = useORTranslation(['PreviewPanel']);

    const countImages = useAppSelector((state) => selectCountImages(state, props.orderId));
    const countPlannings = useAppSelector((state) => selectCountPlannings(state, props.orderId));

    //console.log("countImages: " + countImages + " countPlannings: " + countPlannings);

    const handleClickLogo = (e: React.MouseEvent) => {
		dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
        dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });
		dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: "" });

		const r = document.querySelector(':root');
		// @ts-ignore
		r?.style.setProperty('--img_desc_left_margin', `${0}px`);

		navigate(`/${location?.search ?? ''}`);
	}

    return (
        <div className="previewdivWrapper">
            <span className="previewdiv">
                <br />
                {countImages ? `${countImages} ${t('images', {count: countImages})}` : ''}
                <br />
                {countPlannings ? `${countPlannings}  ${t('plannings', {count: countPlannings})}` : ''}
            </span>
            <div className="nav-logo">
				<img src="/images/ORinspect Logo neg2.svg"
                alt="Logo.svg" onMouseDown={handleClickLogo} className="nav-item-logo" />
			</div>
        </div>
    );

}

export default PreviewPanelInfo;
