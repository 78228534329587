import React, { useCallback, useEffect, useRef, useState } from "react";
import * as cornerstoneTools from "cornerstone-tools";
import cornerstone, { EnabledElement, getEnabledElement } from "cornerstone-core";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import TruncateMarkup from 'react-truncate-markup';
import { AppDispatch, RootState } from "../../../store";
import ToolButtonImage from "./ToolButtonImage";
import BlackMaskTool from "./BlackMaskTool";
import CutToBlackMaskTool from "./CutToBlackMaskTool";
import RotateToBlackMaskTool from "./RotateToBlackMaskTool";
import AcceptBlackMaskTool from "./AcceptBlackMaskTool";
import DeclineBlackMaskTool from "./DeclineBlackMaskTool";
import ReprocessWithBlackMaskTool from "./ReprocessWithBlackMaskTool";
import CloudUploadTool from "./CloudUpload";
import FinalizeTool from "./FinalizeTool";
import BrighterTool from "./BrighterTool";
import DarkerTool from "./DarkerTool";
import LRMarkerTool from "./LRMarkerTool";
import FlippedAnnotationTool from "./FlippedAnnotationTool";
import WhitePointTool from "./WhitePointTool";
import ProcessingROITool from "./ProcessingROI";
import NewWwwcRegionTool from "./NewWwwcRegionTool";
import MultiWwwcRegionsTool from "./MultiWwwcRegionsTool";
import ResolutionMeasurementTool from "./ResolutionMeasurementTool";
import RestorableAngleTool from "./RestorableAngleTool";
import RestorableCobbAngleTool from "./RestorableCobbAngleTool";
import RestorableArrowAnnotateTool from "./RestorableArrowAnnotateTool";
import RestorableLengthTool from "./RestorableLengthTool";
import RestorableTextMarkerTool from "./RestorableTextMarkerTool";
import InvertTool from "./InvertTool";
import FlipHorizontalTool from "./FlipHorizontalTool";
import FlipVerticalTool from "./FlipVerticalTool";
import ResetTool from "./ResetTool";
import RotateRightTool from "./RotateRightTool";
import RotateLeftTool from "./RotateLeftTool";
import Percent100Tool from "./Percent100Tool";
import FitToWindowTool from "./FitToWindowTool";
import FalseColorTool from "./FalseColorTool";
import NewZoomMouseWheelTool from "./NewZoomMouseWheelTool";
import ProbeRawTool from "./ProbeRawTool";
import { Constants } from "../../../Constants";
import { getDrawHandlesOnHover, getFalseColorsActive, getFFTFilterActive, getSelectedToolAtMatrixIndex, getToolInsetActive, selectedWorkitem, selectMatrixColumns, selectMatrixIndex, selectMatrixRows, selectToolInsetVisible, selectToolsPanel, selectViewportSynchronized, setImageProcessing, getViewportStorageType, ViewportStorageType, getCutToBMOnFinalize } from "../ImageDisplaySlice";
import FFTFilterTool from "./FFTFilterTool";
import EllipticalRoiExtendedTool from "./EllipticalRoiExtendedTool";
import RectangleRoiExtendedTool from "./RectangleRoiExtendedTool";
import FreehandRoiExtendedTool from "./FreehandRoiExtendedTool";
//import RevokeTool from "./RevokeTool";
import PipeWallThicknessTool from "./PipeWallThicknessTool";
import LineProfileTool from "./LineProfileTool";
import { storeData, edit, revoke } from "./Restorable.js";
import WwwcMagnifyTool from "./WwwcMagnifyTool";
import LengthCalibrationTool from "./LengthCalibrationTool";
import EraserExtendedTool from "./EraserExtendedTool";
import SNRTool from "./SNRTool";
import MagnifyTool from "./MagnifyTool";
import RotateTool from "./NewRotateTool";
import './Tools.scss';
import { useORTranslation } from "../../Localization/ORLocalization";
import { ToolsListEntry } from "../../../App";
import { selectTheme } from "../../OrderList/MainOrderListSlice";
import { submit } from "../../Utils/TextAnnotationInputDialog";
import { getFilterForColor } from "./utils";
import { selectAcquistionButtonActive } from "../../Akquisition/AcquisitionSlice";
import { getImagePathForCurrentTheme, getLayout, getToolList } from "../../OrderList/OrdersSlice";

import { selectSeriesDataForWorkitem, selectInstanceForWorkitem, getSelectedWorkitemState, getTargetExposureIndexForSelectedWorkitem, getLateralityForSelectedWorkitem } from '../../OrderList/OrdersSlice';
import { useResumeWorkitemMutation, useFinalizeArtifactMutation, useSetNewBlackMaskMutation, useSetNewRoiMutation, useCloudUploadMutation } from '../../../apis/apiSlice';
import { throttle, debounce } from 'throttle-debounce';

const getToolState = cornerstoneTools.getToolState;
const removeToolState = cornerstoneTools.removeToolState;
const addToolState = cornerstoneTools.addToolState;
const BaseAnnotationTool = cornerstoneTools.importInternal('base/BaseAnnotationTool');


let toolsRenderedLength = 0;

type ToolsProps = {
	orderId: string;
};

function findToolsByName(data: ToolsListEntry[], name: string): ToolsListEntry | undefined {
	function iter(a: ToolsListEntry) {
		if (a.name === name) {
			result = a;
			return true;
		}
		return Array.isArray(a.subMenu) && a.subMenu.some(iter);
	}

	var result;
	data?.some(iter);
	return result
}


export const getTargetIdsForElement = (enabledElement: EnabledElement): string[] | undefined => {
	let targetIds: string[] | undefined = undefined;
	// @ts-ignore
	if (enabledElement.image?.image_raw?.targetId) {
		// @ts-ignore
		targetIds = [enabledElement.image?.targetId, enabledElement.image?.image_raw?.targetId];
		// @ts-ignore
	} else if (enabledElement.image?.image_org) {
		// @ts-ignore
		targetIds = [enabledElement.image?.targetId, enabledElement.image?.image_org?.targetId];;
	} else {
		// @ts-ignore
		targetIds = [enabledElement.image?.targetId];
	}
	return targetIds;
}


export const addAllToolsToElement = (element: EnabledElement, matrixIndex: number, configuration_TextMarker: any, configuration_ArrowAnnotate: any, drawHandlesOnHover: boolean) => {
	if (!cornerstoneTools.getToolForElement(element.element, "BlackMask")) cornerstoneTools.addToolForElement(element.element, BlackMaskTool);
	if (!cornerstoneTools.getToolForElement(element.element, "CutToBlackMask")) cornerstoneTools.addToolForElement(element.element, CutToBlackMaskTool);
	if (!cornerstoneTools.getToolForElement(element.element, "RotateToBlackMask")) cornerstoneTools.addToolForElement(element.element, RotateToBlackMaskTool);
	if (!cornerstoneTools.getToolForElement(element.element, "AcceptBlackMask")) cornerstoneTools.addToolForElement(element.element, AcceptBlackMaskTool);
	if (!cornerstoneTools.getToolForElement(element.element, "DeclineBlackMask")) cornerstoneTools.addToolForElement(element.element, DeclineBlackMaskTool);
	if (!cornerstoneTools.getToolForElement(element.element, "ReprocessWithBlackMask")) cornerstoneTools.addToolForElement(element.element, ReprocessWithBlackMaskTool);

	if (!cornerstoneTools.getToolForElement(element.element, "Finalize")) cornerstoneTools.addToolForElement(element.element, FinalizeTool);

	if (!cornerstoneTools.getToolForElement(element.element, "Brighter")) cornerstoneTools.addToolForElement(element.element, BrighterTool);
	if (!cornerstoneTools.getToolForElement(element.element, "Darker")) cornerstoneTools.addToolForElement(element.element, DarkerTool);
	if (!cornerstoneTools.getToolForElement(element.element, "LRMarker")) cornerstoneTools.addToolForElement(element.element, LRMarkerTool);
	
	if (!cornerstoneTools.getToolForElement(element.element, "FlippedAnnotation")) cornerstoneTools.addToolForElement(element.element, FlippedAnnotationTool);

	if (!cornerstoneTools.getToolForElement(element.element, "CloudUpload")) cornerstoneTools.addToolForElement(element.element, CloudUploadTool);

	if (!cornerstoneTools.getToolForElement(element.element, "Whitepoint")) cornerstoneTools.addToolForElement(element.element, WhitePointTool);
	if (!cornerstoneTools.getToolForElement(element.element, "ProcessingROI")) cornerstoneTools.addToolForElement(element.element, ProcessingROITool);
	if (!cornerstoneTools.getToolForElement(element.element, "Wwwc")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.WwwcTool);
	if (!cornerstoneTools.getToolForElement(element.element, "NewWwwcRegion")) cornerstoneTools.addToolForElement(element.element, NewWwwcRegionTool, { matrixIndex: matrixIndex, configuration: { drawHandlesOnHover: drawHandlesOnHover } });
	if (!cornerstoneTools.getToolForElement(element.element, "MultiWwwcRegions")) cornerstoneTools.addToolForElement(element.element, MultiWwwcRegionsTool, { matrixIndex: matrixIndex, configuration: { drawHandlesOnHover: drawHandlesOnHover } });
	if (!cornerstoneTools.getToolForElement(element.element, "Invert")) cornerstoneTools.addToolForElement(element.element, InvertTool);
	if (!cornerstoneTools.getToolForElement(element.element, "FlipHorizontal")) cornerstoneTools.addToolForElement(element.element, FlipHorizontalTool);
	if (!cornerstoneTools.getToolForElement(element.element, "FlipVertical")) cornerstoneTools.addToolForElement(element.element, FlipVerticalTool);
	if (!cornerstoneTools.getToolForElement(element.element, "Reset")) cornerstoneTools.addToolForElement(element.element, ResetTool);
	if (!cornerstoneTools.getToolForElement(element.element, "RotateRight")) cornerstoneTools.addToolForElement(element.element, RotateRightTool);
	if (!cornerstoneTools.getToolForElement(element.element, "RotateLeft")) cornerstoneTools.addToolForElement(element.element, RotateLeftTool);
	//if (!cornerstoneTools.getToolForElement(element.element, "Rotate")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.RotateTool);
	if (!cornerstoneTools.getToolForElement(element.element, "Rotate")) cornerstoneTools.addToolForElement(element.element, RotateTool);
	//if (!cornerstoneTools.getToolForElement(element.element, "Probe")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.ProbeTool);
	if (!cornerstoneTools.getToolForElement(element.element, "ProbeRaw")) cornerstoneTools.addToolForElement(element.element, ProbeRawTool);
	//if (!cornerstoneTools.getToolForElement(element.element, "ArrowAnnotate")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.ArrowAnnotateTool, { configuration: configuration_ArrowAnnotate });
	if (!cornerstoneTools.getToolForElement(element.element, "RestorableArrowAnnotate")) cornerstoneTools.addToolForElement(element.element, RestorableArrowAnnotateTool, { configuration: configuration_ArrowAnnotate });
	//if (!cornerstoneTools.getToolForElement(element.element, "TextMarker")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.TextMarkerTool, { configuration: configuration_TextMarker });
	if (!cornerstoneTools.getToolForElement(element.element, "RestorableTextMarker")) cornerstoneTools.addToolForElement(element.element, RestorableTextMarkerTool, { configuration: configuration_TextMarker });
	//if (!cornerstoneTools.getToolForElement(element.element, "Length")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.LengthTool);
	if (!cornerstoneTools.getToolForElement(element.element, "RestorableLength")) cornerstoneTools.addToolForElement(element.element, RestorableLengthTool, { configuration: { drawHandlesOnHover: drawHandlesOnHover } });
	//if (!cornerstoneTools.getToolForElement(element.element, "Angle")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.AngleTool);
	if (!cornerstoneTools.getToolForElement(element.element, "RestorableAngle")) cornerstoneTools.addToolForElement(element.element, RestorableAngleTool, { configuration: { drawHandlesOnHover: drawHandlesOnHover } });
	if (!cornerstoneTools.getToolForElement(element.element, "RestorableCobbAngle")) cornerstoneTools.addToolForElement(element.element, RestorableCobbAngleTool, { configuration: { drawHandlesOnHover: drawHandlesOnHover } });
	if (!cornerstoneTools.getToolForElement(element.element, "Magnify")) cornerstoneTools.addToolForElement(element.element, MagnifyTool);
	//if (!cornerstoneTools.getToolForElement(element.element, "Magnify")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.MagnifyTool);
	if (!cornerstoneTools.getToolForElement(element.element, "Pan")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.PanTool);
	if (!cornerstoneTools.getToolForElement(element.element, "FitToWindow")) cornerstoneTools.addToolForElement(element.element, FitToWindowTool);
	if (!cornerstoneTools.getToolForElement(element.element, "FalseColor")) cornerstoneTools.addToolForElement(element.element, FalseColorTool);
	if (!cornerstoneTools.getToolForElement(element.element, "FFTFilter")) cornerstoneTools.addToolForElement(element.element, FFTFilterTool);
	if (!cornerstoneTools.getToolForElement(element.element, "ResolutionMeasurement")) cornerstoneTools.addToolForElement(element.element, ResolutionMeasurementTool, { matrixIndex: matrixIndex, configuration: { drawHandlesOnHover: drawHandlesOnHover } });
	// if (!cornerstoneTools.getToolForElement(element.element, "GrayscaleLineProfile")) cornerstoneTools.addToolForElement(element.element, GrayscaleLineProfileTool, { matrixIndex: matrixIndex, configuration: {drawHandlesOnHover: drawHandlesOnHover } });
	if (!cornerstoneTools.getToolForElement(element.element, "Percent100")) cornerstoneTools.addToolForElement(element.element, Percent100Tool);
	if (!cornerstoneTools.getToolForElement(element.element, "EraserExtended")) cornerstoneTools.addToolForElement(element.element, EraserExtendedTool);
	//if (!cornerstoneTools.getToolForElement(element.element, "Revoke")) cornerstoneTools.addToolForElement(element.element, RevokeTool);
	if (!cornerstoneTools.getToolForElement(element.element, "EllipticalRoiExtended")) cornerstoneTools.addToolForElement(element.element, EllipticalRoiExtendedTool, { configuration: { drawHandlesOnHover: drawHandlesOnHover } });
	if (!cornerstoneTools.getToolForElement(element.element, "RectangleRoiExtended")) cornerstoneTools.addToolForElement(element.element, RectangleRoiExtendedTool, { configuration: { drawHandlesOnHover: drawHandlesOnHover } });
	if (!cornerstoneTools.getToolForElement(element.element, "FreehandRoiExtended")) cornerstoneTools.addToolForElement(element.element, FreehandRoiExtendedTool, { configuration: { drawHandlesOnHover: drawHandlesOnHover } });
	//if (!cornerstoneTools.getToolForElement(element.element, "FreehandRoi")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.FreehandRoiTool);
	if (!cornerstoneTools.getToolForElement(element.element, "PipeWallThickness")) cornerstoneTools.addToolForElement(element.element, PipeWallThicknessTool, { matrixIndex: matrixIndex, autoCalc: true, configuration: { drawHandlesOnHover: drawHandlesOnHover } });
	if (!cornerstoneTools.getToolForElement(element.element, "LengthCalibration")) cornerstoneTools.addToolForElement(element.element, LengthCalibrationTool, { matrixIndex: matrixIndex, configuration: { drawHandlesOnHover: drawHandlesOnHover } });
	if (!cornerstoneTools.getToolForElement(element.element, "LineProfile")) cornerstoneTools.addToolForElement(element.element, LineProfileTool, { matrixIndex: matrixIndex, configuration: { drawHandlesOnHover: drawHandlesOnHover } });
	if (!cornerstoneTools.getToolForElement(element.element, "WwwcMagnify")) cornerstoneTools.addToolForElement(element.element, WwwcMagnifyTool);
	if (!cornerstoneTools.getToolForElement(element.element, "SNR")) cornerstoneTools.addToolForElement(element.element, SNRTool, { matrixIndex: matrixIndex, configuration: { drawHandlesOnHover: drawHandlesOnHover } });


	const toolList = cornerstoneTools.store?.state?.tools?.filter(
		(tool: any) =>
			tool.element === element.element
	);

	toolList.forEach((tool: any) => {
		cornerstoneTools.setToolEnabledForElement(element.element, tool.name);
	})

	//if (!cornerstoneTools.getToolForElement(element.element, "ScaleOverlay")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.ScaleOverlayTool);
}

export const addExportToolsToElement = (element: EnabledElement, matrixIndex: number) => {
	if (!cornerstoneTools.getToolForElement(element.element, "BlackMask")) cornerstoneTools.addToolForElement(element.element, BlackMaskTool);
	if (!cornerstoneTools.getToolForElement(element.element, "CutToBlackMask")) cornerstoneTools.addToolForElement(element.element, CutToBlackMaskTool);
	if (!cornerstoneTools.getToolForElement(element.element, "RotateToBlackMask")) cornerstoneTools.addToolForElement(element.element, RotateToBlackMaskTool);
	if (!cornerstoneTools.getToolForElement(element.element, "AcceptBlackMask")) cornerstoneTools.addToolForElement(element.element, AcceptBlackMaskTool);
	if (!cornerstoneTools.getToolForElement(element.element, "DeclineBlackMask")) cornerstoneTools.addToolForElement(element.element, DeclineBlackMaskTool);
	if (!cornerstoneTools.getToolForElement(element.element, "ReprocessWithBlackMask")) cornerstoneTools.addToolForElement(element.element, ReprocessWithBlackMaskTool);

	if (!cornerstoneTools.getToolForElement(element.element, "Finalize")) cornerstoneTools.addToolForElement(element.element, FinalizeTool);

	if (!cornerstoneTools.getToolForElement(element.element, "Brighter")) cornerstoneTools.addToolForElement(element.element, BrighterTool);
	if (!cornerstoneTools.getToolForElement(element.element, "Darker")) cornerstoneTools.addToolForElement(element.element, DarkerTool);
	if (!cornerstoneTools.getToolForElement(element.element, "LRMarker")) cornerstoneTools.addToolForElement(element.element, LRMarkerTool);

	if (!cornerstoneTools.getToolForElement(element.element, "Whitepoint")) cornerstoneTools.addToolForElement(element.element, WhitePointTool);
	if (!cornerstoneTools.getToolForElement(element.element, "ProcessingROI")) cornerstoneTools.addToolForElement(element.element, ProcessingROITool);
	if (!cornerstoneTools.getToolForElement(element.element, "Wwwc")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.WwwcTool);

	if (!cornerstoneTools.getToolForElement(element.element, "Invert")) cornerstoneTools.addToolForElement(element.element, InvertTool);
	if (!cornerstoneTools.getToolForElement(element.element, "FlipHorizontal")) cornerstoneTools.addToolForElement(element.element, FlipHorizontalTool);
	if (!cornerstoneTools.getToolForElement(element.element, "FlipVertical")) cornerstoneTools.addToolForElement(element.element, FlipVerticalTool);
	if (!cornerstoneTools.getToolForElement(element.element, "Reset")) cornerstoneTools.addToolForElement(element.element, ResetTool);
	if (!cornerstoneTools.getToolForElement(element.element, "RotateRight")) cornerstoneTools.addToolForElement(element.element, RotateRightTool);
	if (!cornerstoneTools.getToolForElement(element.element, "RotateLeft")) cornerstoneTools.addToolForElement(element.element, RotateLeftTool);
	//if (!cornerstoneTools.getToolForElement(element.element, "Rotate")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.RotateTool);
	if (!cornerstoneTools.getToolForElement(element.element, "Rotate")) cornerstoneTools.addToolForElement(element.element, RotateTool);
	//if (!cornerstoneTools.getToolForElement(element.element, "Length")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.LengthTool);
	if (!cornerstoneTools.getToolForElement(element.element, "Magnify")) cornerstoneTools.addToolForElement(element.element, MagnifyTool);
	//if (!cornerstoneTools.getToolForElement(element.element, "Magnify")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.MagnifyTool);
	if (!cornerstoneTools.getToolForElement(element.element, "Pan")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.PanTool);
	if (!cornerstoneTools.getToolForElement(element.element, "FitToWindow")) cornerstoneTools.addToolForElement(element.element, FitToWindowTool);

	const toolList = cornerstoneTools.store?.state?.tools?.filter(
		(tool: any) =>
			tool.element === element.element
	);

	toolList.forEach((tool: any) => {
		cornerstoneTools.setToolEnabledForElement(element.element, tool.name);
	})

	//if (!cornerstoneTools.getToolForElement(element.element, "ScaleOverlay")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.ScaleOverlayTool);
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const panActivateKey = 'p'
let isKeyDown: boolean = false;

const Tools = (props: ToolsProps) => {
	const dispatch = useAppDispatch();
	const rootDivElement = useRef<HTMLDivElement>(null);

	const { t } = useORTranslation(['Tools']);
	const { language } = useORTranslation([])

	const [selectedTool, setSelectedTool] = useState<ToolsListEntry | undefined>(undefined);
	const [value, setValue] = useState<string>('');

	const [isInverted, setIsInverted] = useState<boolean | undefined>(false);
	const [isVflip, setIsVflip] = useState<boolean | undefined>(false);
	const [isHflip, setIsHflip] = useState<boolean | undefined>(false);

	const [isCutToBM, setCutToBM] = useState<boolean>(false);
	const [isAcceptedBM, setAcceptedBM] = useState<boolean>(false);
	const [isRotateToBM, setRotateToBM] = useState<boolean>(false);

	const [openSubmenuItem, setOpenSubmenuItem] = useState<string>("");
	const [toolsbuttonsOverflow, setToolsbuttonsOverflow] = useState<number>(0);
	const [isOverflowVisible, setIsOverflowVisible] = useState<boolean>(false);

	const [SetNewRoi, {error}] = useSetNewRoiMutation();
	const [SetNewBlackMask] = useSetNewBlackMaskMutation();
	const [CloudUpload] = useCloudUploadMutation();

	const [FinalizeArtifact] = useFinalizeArtifactMutation();

	const [ResumeWorkitem] = useResumeWorkitemMutation();

	const currentMatrixColumns: number | undefined = useAppSelector(state => selectMatrixColumns(state));
	const currentMatrixRows: number | undefined = useAppSelector(state => selectMatrixRows(state));
	const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
	const currentSelectedTool: ToolsListEntry | undefined = useAppSelector((state) => getSelectedToolAtMatrixIndex(state, selectedMatrixIndex ?? 0));
	const isViewportSynchronized: boolean = useAppSelector(state => selectViewportSynchronized(state));
	const isFalseColorsActive: boolean | undefined = useAppSelector((state) => getFalseColorsActive(state, selectedMatrixIndex ?? 0));
	const isFFTFilterActive: boolean | undefined = useAppSelector((state) => getFFTFilterActive(state, selectedMatrixIndex ?? 0));
	const isAcquisitionButtonActive: boolean = useAppSelector((state) => selectAcquistionButtonActive(state)) ?? false;
	const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
	const currentWorkitemState: string = useAppSelector((state) => getSelectedWorkitemState(state));

	const currentSeriesAsString: string | undefined = useAppSelector((state) => selectSeriesDataForWorkitem(state, currentWorkitemId));
	//const currentSeries = currentSeriesAsString ? JSON.parse(currentSeriesAsString) : undefined;
	const currentInstanceAsString: string | undefined = useAppSelector((state) => selectInstanceForWorkitem(state, currentWorkitemId));
	const currentInstance = currentInstanceAsString ? JSON.parse(currentInstanceAsString) : undefined;

	const storeViewport: ViewportStorageType = useAppSelector((state) => getViewportStorageType(state));
	const imageLoadStatus = useAppSelector((state) => state.Orders.imageLoadStatus);
	const currentTheme: string = useAppSelector((state) => selectTheme(state));
	const drawHandlesOnHover: boolean = useAppSelector((state) => getDrawHandlesOnHover(state));
	const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

	const targetExposureIndex: number | undefined = useAppSelector((state) => getTargetExposureIndexForSelectedWorkitem(state));
	const workitemLaterality: string | undefined = useAppSelector((state) => getLateralityForSelectedWorkitem(state));

	const cutToBMOnFinalize: boolean = useAppSelector((state) => getCutToBMOnFinalize(state));

	const isToolInsetActive: boolean | undefined = useAppSelector((state) => getToolInsetActive(state, selectedMatrixIndex ?? 0));

	const tools: ToolsListEntry[] = useAppSelector((state) => getToolList(state));

	const layout: any = useAppSelector((state) => getLayout(state));

	const selectedToolRef = useRef<ToolsListEntry | undefined>();
	selectedToolRef.current = selectedTool;

	const currentWorkitemIdRef = useRef<string>();
	currentWorkitemIdRef.current = currentWorkitemId;
	const currentInstanceAsStringRef = useRef<string | undefined>();
	currentInstanceAsStringRef.current = currentInstanceAsString;
	const currentSeriesAsStringRef = useRef<string | undefined>();
	currentSeriesAsStringRef.current = currentSeriesAsString;
	const currentWorkitemStateRef = useRef<string>();
	currentWorkitemStateRef.current = currentWorkitemState;

	const targetExposureIndexRef = useRef<number | undefined>();
	targetExposureIndexRef.current = targetExposureIndex;
	 
	const currentWorkitemLateralityRef = useRef<string>();
	currentWorkitemLateralityRef.current = workitemLaterality;

	const isToolInsetActiveRef = useRef<boolean | undefined>();
	isToolInsetActiveRef.current = isToolInsetActive;

	const layoutRef = useRef<any>();
	layoutRef.current = layout;

	const toolsRef = useRef<ToolsListEntry[] | undefined>();
	toolsRef.current = tools;

	const valueRef = useRef<string>();
	valueRef.current = value;

	const toolsInputValueChanged = (data: any, value: string) => {
		// store changed annotation
		const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
		if (cornerstoneElements) {
			const display_element: EnabledElement | undefined = cornerstoneElements.find(
				(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
			);
			if (display_element) {
				const toolData = getToolState(display_element.element, data?.toolName);
				if (toolData?.data && Array.isArray(toolData?.data) && toolData?.data.length > 0) {
					// find matching
					var index = -1;
					for (var i = 0; i < toolData?.data.length; i++) {
						//console.log(toolData?.data[i]?.uuid);
						if (toolData?.data[i] === data)
							index = i;
					}
					if (index !== -1) {
						if (toolData?.data[index]?.storeToolData && toolData?.data[index]?.annotationId !== -1) {
							toolData.data[index].storeToolData.text = value;
							const targetIds: string[] | undefined = getTargetIdsForElement(display_element);
							edit(toolData?.data[index], targetIds, dispatch);
						}
					} else {
						console.log("tool data not found: " + data?.toolName);
					}
				}
			}
		}
	}

	const getMatrixIndexFromEnabledElement = (element: HTMLElement | undefined): number => {
		let ret: number = -1;
		if (element) {
			const matrixIndexStr: string | undefined = element.id.split("_").pop();
			if (matrixIndexStr) {
				const matrixIndex: number = parseInt(matrixIndexStr);
				if (!isNaN(matrixIndex)) {
					ret = matrixIndex;
				}
			}
		}
		return ret;
	}

	const activateToolContext = useCallback((toolName: string, currentMatrixIndex: number) => {

		if (layoutRef?.current?.startWithToolContextPanel && !isToolInsetActiveRef?.current) {
			dispatch({ type: "ImageDisplay/setToolInsetVisible", payload: true });
		}

		if (toolName === 'PipeWallThickness') {
			dispatch({ type: "ImageDisplay/setPipeWallThicknessHistActive", payload: { matrixIndex: currentMatrixIndex, isActive: true } });
			dispatch({ type: "ImageDisplay/setPipeWallThicknessChartActive", payload: { matrixIndex: currentMatrixIndex, isActive: true } });
			dispatch({ type: "ImageDisplay/setSelectedToolTab", payload: { matrixIndex: currentMatrixIndex, selectedTooltab: "PipeWallThickness" } });
		} else {
			dispatch({ type: "ImageDisplay/setPipeWallThicknessHistActive", payload: { matrixIndex: currentMatrixIndex, isActive: false } });
		}
		if (toolName === 'LineProfile') {
			dispatch({ type: "ImageDisplay/setLineProfileActive", payload: { matrixIndex: currentMatrixIndex, isActive: true } });
			dispatch({ type: "ImageDisplay/setLineProfileChartActive", payload: { matrixIndex: currentMatrixIndex, isActive: true } });
			dispatch({ type: "ImageDisplay/setSelectedToolTab", payload: { matrixIndex: currentMatrixIndex, selectedTooltab: "GrayscaleProfile" } });
		} else {
			dispatch({ type: "ImageDisplay/setLineProfileActive", payload: { matrixIndex: currentMatrixIndex, isActive: false } });
		}
		/*
		if (toolName === 'BlackMask') {
			dispatch({ type: "ImageDisplay/setBlackMaskActive", payload: { matrixIndex: currentMatrixIndex, isActive: true } });
			dispatch({ type: "ImageDisplay/setSelectedToolTab", payload: { matrixIndex: currentMatrixIndex, selectedTooltab: "BlackMask" } });
		} else {
			dispatch({ type: "ImageDisplay/setBlackMaskActive", payload: { matrixIndex: currentMatrixIndex, isActive: false } });
		}
		*/
		if (toolName === 'ResolutionMeasurement') {
			dispatch({ type: "ImageDisplay/setResolutionMeasurementActive", payload: { matrixIndex: currentMatrixIndex, isActive: true } });
			dispatch({ type: "ImageDisplay/setResolutionMeasurementChartActive", payload: { matrixIndex: currentMatrixIndex, isActive: true } });
			dispatch({ type: "ImageDisplay/setSelectedToolTab", payload: { matrixIndex: currentMatrixIndex, selectedTooltab: "ResolutionMeasurement" } });
		} else {
			dispatch({ type: "ImageDisplay/setResolutionMeasurementActive", payload: { matrixIndex: currentMatrixIndex, isActive: false } });
		}
		if (toolName === 'LengthCalibration') {
			dispatch({ type: "ImageDisplay/setLengthCalibrationActive", payload: { matrixIndex: currentMatrixIndex, isActive: true } });
			dispatch({ type: "ImageDisplay/setLengthCalibrationChartActive", payload: { matrixIndex: currentMatrixIndex, isActive: true } });
			dispatch({ type: "ImageDisplay/setSelectedToolTab", payload: { matrixIndex: currentMatrixIndex, selectedTooltab: "LengthCalibration" } });
		} else {
			dispatch({ type: "ImageDisplay/setLengthCalibrationActive", payload: { matrixIndex: currentMatrixIndex, isActive: false } });
		}
	}, [dispatch])

	const handleSelect = useCallback((selectedToolPar: ToolsListEntry, currentMatrixIndex: number) => {
		const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
		const display_element: EnabledElement | undefined = cornerstoneElements.find(
			(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${currentMatrixIndex}`
		);
		if (selectedToolPar.subMenu?.length > 0) {
		    if (selectedToolPar.name === "BlackMaskMenu" && currentWorkitemStateRef?.current === "COMPLETED") {
		        return;
		    }
			setOpenSubmenuItem(selectedToolPar.name);
		} else {
			if (display_element && display_element.element) {
			        if (selectedToolPar.name === "Whitepoint" && currentWorkitemStateRef?.current === "COMPLETED") {
		                return;
		            }
		            if (selectedToolPar.name === "LRMarker") {
		                if(currentWorkitemStateRef?.current === "COMPLETED")
		                    return;
		                  const state = getToolState(display_element.element, "LRMarker");
				          if (state && state.data && state.data.length > 0) {
				            return
		                  }
		            }
				if (selectedToolPar.config !== undefined) {
					let config: any = { mouseButtonMask: 1, reset: true };

					for (var k in selectedToolPar.config) {
						//console.log("add config key: "+k+" with val "+selectedToolPar.config[k]);
						config[k] = selectedToolPar.config[k];
					}
					cornerstoneTools.setToolActiveForElement(display_element.element, selectedToolPar.name, config);
				} else {
					cornerstoneTools.setToolActiveForElement(display_element.element, selectedToolPar.name, { mouseButtonMask: 1, reset: true });
				}
				if (selectedToolPar.hasState) {
					dispatch({ type: "ImageDisplay/setMatrixSelectedTool", payload: { matrixIndex: currentMatrixIndex, matrixSelectedTool: selectedToolPar } });
					dispatch({ type: "ImageDisplay/setActiveToolIcon", payload: selectedToolPar.icon });
					setSelectedTool(selectedToolPar);
				}

				activateToolContext(selectedToolPar.name, currentMatrixIndex);

				setIsInverted(display_element.viewport?.invert);
				setIsVflip(display_element.viewport?.vflip);
				setIsHflip(display_element.viewport?.hflip);

				const tool = cornerstoneTools.getToolForElement(display_element.element, selectedToolPar.name);
				if (tool instanceof BaseAnnotationTool) {
					dispatch({ type: "ImageDisplay/setIsOverviewAndAnnotationsVisible", payload: true });
				}
			}
			if (!selectedToolPar.sticky)
				setOpenSubmenuItem('');
		}
		setIsOverflowVisible(false);

	}, [activateToolContext, dispatch]);

	const getTextCallback = (doneChangingTextCallback: (arg0: string | null) => void) => {
		submit(undefined, `${t('arrowAnnotationDescription')}:`, undefined, valueRef?.current ?? '', doneChangingTextCallback)
	};

	const changeTextCallback = (data: any, eventData: any, doneChangingTextCallback: (arg0: string | null) => void) => {
		submit(data, `${t('arrowAnnotationChange')}:`, toolsInputValueChanged, data?.text ?? '', doneChangingTextCallback);
	};


	// viewport synchronization
	const onImageRendered = useCallback((evt: any) => {
		if (evt && evt.detail && evt.detail.viewport) {
			const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
			const displayElements: EnabledElement[] | undefined = cornerstoneElements.filter((element: EnabledElement) => element.element.id.startsWith(Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME));
			if (displayElements) {
				displayElements.forEach((element) => {
					if (element !== evt.detail.enabledElement) {
						if (element && element.viewport) {
							element.viewport.scale = evt.detail.viewport.scale;
							element.viewport.translation = evt.detail.viewport.translation;
							element.viewport.rotation = evt.detail.viewport.rotation;
							element.viewport.hflip = evt.detail.viewport.hflip;
							element.viewport.vflip = evt.detail.viewport.vflip;
							cornerstone.updateImage(element.element);
						}
					}
				});
			}
		}
	}, [])

	const onMeasureCompleted = (ev: any) => {
		// some events come without toolName set, see if there is a name in the respective toolState
		let toolName = undefined;
		if (ev.detail.measurementData)
			toolName = ev.detail.measurementData.toolName;
		if (ev.detail.toolName)
			toolName = ev.detail.toolName;
		//if (toolName === selectedTool?.name) {
		//}

		//console.log("onMeasureCompleted:"+toolName);

		// open tool context
		if (toolName === 'PipeWallThickness') {
			dispatch({ type: "ImageDisplay/setPipeWallThicknessChartActive", payload: { matrixIndex: selectedMatrixIndex, isActive: true } });
		}
		if (toolName === 'LineProfile') {
			dispatch({ type: "ImageDisplay/setLineProfileChartActive", payload: { matrixIndex: selectedMatrixIndex, isActive: true } });
		}
		// TODO make this configurable (React Component vs. Menu)
		/*
		if (toolName === 'BlackMask') {
			dispatch({ type: "ImageDisplay/setBlackMaskActive", payload: { matrixIndex: selectedMatrixIndex, isActive: true } });
		}
		*/
		if (toolName === 'ResolutionMeasurement') {
			dispatch({ type: "ImageDisplay/setResolutionMeasurementChartActive", payload: { matrixIndex: selectedMatrixIndex, isActive: true } });
		}
		if (toolName === 'LengthCalibration') {
			dispatch({ type: "ImageDisplay/setLengthCalibrationChartActive", payload: { matrixIndex: selectedMatrixIndex, isActive: true } });
		}
		if (toolName === 'Whitepoint' && currentWorkitemStateRef?.current !== "COMPLETED") {
			//console.log("whitepoint measure complete");

			const enabledElement: EnabledElement = getEnabledElement(ev.target);
			// @ts-ignore
			if (enabledElement.image?.image_raw?.targetId && currentWorkitemStateRef?.current !== "COMPLETED") {
				// @ts-ignore
				let rawId = enabledElement.image?.image_raw?.targetId;

				// TODO add series and other refs

				dispatch({ type: "ImageDisplay/setImageProcessing", payload: true });

				SetNewRoi({
					artifactId: ev.detail.measurementData.artifactId, raw_artifactId: rawId,
					instanceId: currentInstanceAsStringRef?.current ? JSON.parse(currentInstanceAsStringRef.current!)?.id : '',
					seriesId: currentSeriesAsStringRef?.current ? JSON.parse(currentSeriesAsStringRef.current!)?.id : '',
					workitemId: currentWorkitemIdRef?.current,
					roi: ev.detail.measurementData.roi
				}).unwrap().catch((error: any) => {
					console.error('setnewroi failed with', error);
					ev.detail.measurementData.requestFailed = true;
					dispatch({ type: "ImageDisplay/setImageProcessing", payload: false });
					setTimeout(() => {
						let toolData = getToolState(ev.target, "Whitepoint");
						if (toolData !== undefined && toolData.data) {
							for (let i = 0; i < toolData.data.length; i++) {
								let data = toolData.data[i];
								removeToolState(ev.target, "Whitepoint", data);
							}
							cornerstone.updateImage(ev.target, false);
						}
					}, 2000);
				}
				);
			}

		}
		if (toolName === 'ReprocessWithBlackMask') {
			const enabledElement: EnabledElement = getEnabledElement(ev.target);
			// @ts-ignore
			if (enabledElement.image?.image_raw?.targetId && currentWorkitemStateRef?.current !== "COMPLETED") {
				// @ts-ignore
				let rawId = enabledElement.image?.image_raw?.targetId;


				// TODO add series and other refs

				let points = [];
				if (ev.detail.measurementData) {
					const h = ev.detail.measurementData.handles;
					for (var j = 1; j <= 4; j++) {
						points.push(h[j].x);
						points.push(h[j].y);
					}
				}

				dispatch({ type: "ImageDisplay/setImageProcessing", payload: true });

				SetNewBlackMask({
					// @ts-ignore
					artifactId: /*ev.detail.measurementData.artifactId*/enabledElement.image?.targetId, raw_artifactId: rawId,
					instanceId: currentInstanceAsStringRef?.current ? JSON.parse(currentInstanceAsStringRef.current!)?.id : '',
					seriesId: currentInstanceAsStringRef?.current ? JSON.parse(currentInstanceAsStringRef.current!)?.id : '',
					workitemId: currentWorkitemIdRef?.current,
					blackmask: points
				}).unwrap().catch((error: any) => {
					console.error('setnewbm failed with', error);
					//ev.detail.measurementData.requestFailed = true;
					dispatch({ type: "ImageDisplay/setImageProcessing", payload: false });
				}
				);
			}
			// else log error, wrt. no raw image?
		}
		if (toolName === 'CloudUpload') {
			const enabledElement: EnabledElement = getEnabledElement(ev.target);
			let connection = "";
			if (ev.detail.connection)
				connection = ev.detail.connection;
			let mapping = "default";
			if (ev.detail.mapping)
				mapping = ev.detail.mapping;
			CloudUpload({
				// @ts-ignore
				instanceId: currentInstanceAsStringRef?.current ? JSON.parse(currentInstanceAsStringRef.current!)?.id : '',
				connection: connection,
				mapping: mapping
			});
		}
		if (toolName === 'Finalize' && currentWorkitemStateRef?.current !== "COMPLETED") {
			const enabledElement: EnabledElement = getEnabledElement(ev.target);
			// @ts-ignore
			if (enabledElement.image?.image_raw?.targetId) {
			    // switch to Pan tool (in case whitepoint tool is currently active and we do not want a whitepoint on a finished image)
				if (cornerstoneTools.getToolForElement(enabledElement.element, 'Pan')) {
					cornerstoneTools.setToolActiveForElement(enabledElement.element, "Pan", { mouseButtonMask: 1 });
					const panToolsListEntry: ToolsListEntry | undefined = findToolsByName(tools, 'Pan');
					if (panToolsListEntry) {
						dispatch({ type: "ImageDisplay/setActiveToolIcon", payload: panToolsListEntry?.icon });
						dispatch({ type: "ImageDisplay/setMatrixSelectedTool", payload: { matrixIndex: selectedMatrixIndex, matrixSelectedTool: panToolsListEntry } });
					}
				}
				const image = cornerstone.getImage(enabledElement.element);
				let flip = "none";
				let borders = [0, 0, 0, 0];
				let window = { width: 100, center: 50, name: "default" };

				let setLaterality = "none";
				if (workitemLaterality !== undefined) {
					setLaterality = workitemLaterality;
				}

				//dispatch({ type: "ImageDisplay/setImageProcessing", payload: true });
				let viewport = enabledElement?.viewport;
				if (viewport) {
					// @ts-ignore
					if (image.cutwidth) {
						// @ts-ignore
						borders[0] = image.cutleft;
						// @ts-ignore
						borders[1] = image.cutright;
						// @ts-ignore
						borders[2] = image.cuttop;
						// @ts-ignore
						borders[3] = image.cutbottom;
					}

					if (viewport.hflip)
						flip = "horizontal";
					if (viewport.vflip)
						flip = "vertical";
					if (viewport.hflip && viewport.vflip)
						flip = "both";

					window.width = viewport.voi?.windowWidth ? viewport.voi?.windowWidth : 100;
					window.center = viewport.voi?.windowCenter ? viewport.voi?.windowCenter : 50;
				}

				let bm = undefined;
				let dk = 0;
				const bmState = getToolState(enabledElement.element, "BlackMask");
				if (bmState !== undefined && bmState.data !== undefined && bmState.data.length > 0) {
					// TODO other states?
					const state = bmState.data[0];
					let j = 0;
					bm = [];
					for (let i = 1; i <= 4; i++) {
						bm[j] = state.handles[i].x;
						j++;
						bm[j] = state.handles[i].y;
						j++;
					}
					if (state.darkening)
						dk = state.darkening;
					// @ts-ignore
					if (image.cutwidth || cutToBMOnFinalize) {
						let minx = image.width, miny = image.height, maxx = 0, maxy = 0;
						for (let i = 1; i <= 4; i++) {
							if (state.handles[i].x < minx)
								minx = state.handles[i].x;
							if (state.handles[i].x > maxx)
								maxx = state.handles[i].x;
							if (state.handles[i].y < miny)
								miny = state.handles[i].y;
							if (state.handles[i].y > maxy)
								maxy = state.handles[i].y;
						}
						// @ts-ignore
						borders[0] = minx;
						// @ts-ignore
						borders[1] = image.width - maxx;
						// @ts-ignore
						borders[2] = miny;
						// @ts-ignore
						borders[3] = image.height - maxy;
					}
				} // if have bm

				let svgs = [];
				let svg = "";
				let svgx = 0, svgy = 0;
				const lrMarkerState = getToolState(enabledElement.element, "LRMarker");
				if (lrMarkerState && lrMarkerState.data && lrMarkerState.data.length > 0) {
					// TODO other states?
					const state = lrMarkerState.data[0];

                    if(state.setLaterality !== undefined)
                        setLaterality = state.setLaterality;

					let path = state.svg.slice(0, -2);

					let black = path + " fill=\"white\" stroke=\"black\" stroke-width=\"2px\" />";

					//let white = path + " fill=\"white\" transform=\"translate(-5,-5)\" />";
					let white = path + " fill=\"white\" />";

					//console.log(black);
					//console.log(white);

					svg = "<svg viewBox=\"" + 0 + " " + (-state.fontSize) + " " + state.fontSize + " " + state.fontSize + "\" >" + black + "</svg>";

					svgx = state.handles.start.x;
					svgy = state.handles.start.y;

					if (borders[0] !== 0)
						svgx -= borders[0];

					if (borders[2] !== 0)
						svgy -= borders[2];

					if (flip === "horizontal") {
						//console.log("fliph");
						svgx = (image.width - borders[0] - borders[1]) - svgx;
					}
					if (flip === "vertical") {
						//console.log("flipv");
						svgy = (image.height - borders[2] - borders[3]) - svgy;
					}

					if (flip === "both") {
						//console.log("flipb");
						svgx = (image.width - borders[0] - borders[1]) - svgx;
						svgy = (image.height - borders[2] - borders[3]) - svgy;
					}

					if (viewport && viewport.rotation !== undefined && viewport.rotation !== 0.0) {
						//console.log("rotation");
						const angle = viewport.rotation;
						const rotationRad = angle * Math.PI / 180.0;
						let ox = (image.width - borders[0] - borders[1]) / 2;
						let oy = (image.height - borders[2] - borders[3]) / 2;
						svgx -= ox;
						svgy -= oy
						let ttx = (svgx * Math.cos(rotationRad) - svgy * Math.sin(rotationRad));
						let tty = (svgy * Math.cos(rotationRad) + svgx * Math.sin(rotationRad));
						svgx = ttx + ox;
						svgy = tty + oy;
					}
					svgy -= state.fontSize;
					
					svgs.push({svg, svgx, svgy});
				} // if have overlay

				const flippedAnnoState = getToolState(enabledElement.element, "FlippedAnnotation");
				if (flippedAnnoState && flippedAnnoState.data && flippedAnnoState.data.length > 0) {
					const state = flippedAnnoState.data[0];
					let path = state.svg.slice(0, -2);

					let black = path + " fill=\"white\" stroke=\"black\" stroke-width=\"2px\" />";

					//let white = path + " fill=\"white\" transform=\"translate(-5,-5)\" />";
					let white = path + " fill=\"white\" />";

					//console.log(black);
					//console.log(white);

					let sizex = state.text.length * state.fontSize;
					svg = "<svg viewBox=\"" + 0 + " " + (-state.fontSize) + " " + sizex + " " + state.fontSize + "\" >" + black + "</svg>";
					//console.log("sending svg:" + svg);
					
					const handle = state.handles.start;
					svgx = handle.x;
					svgy = handle.y;

					//console.log("borders[0]:" + borders[0]);
					if (borders[0] !== 0)
						svgx -= borders[0];

					//console.log("borders[2]:" + borders[2]);
					if (borders[2] !== 0)
						svgy -= borders[2];

					if (flip === "horizontal") {
						//console.log("fliph");
						svgx = (image.width - borders[0] - borders[1]) - svgx;
					}
					if (flip === "vertical") {
						//console.log("flipv");
						svgy = (image.height - borders[2] - borders[3]) - svgy;
					}
					if (viewport && viewport.rotation && viewport.rotation !== 0.0) {
						//console.log("rotation");
						const rotationRad = viewport.rotation * Math.PI / 180.0;
						let ox = (image.width - borders[0] - borders[1]) / 2;
						let oy = (image.height - borders[2] - borders[3]) / 2;
						svgx -= ox;
						svgy -= oy
						let ttx = (svgx * Math.cos(rotationRad) - svgy * Math.sin(rotationRad));
						let tty = (svgy * Math.cos(rotationRad) + svgx * Math.sin(rotationRad));
						svgx = ttx + ox;
						svgy = tty + oy;
					}
					svgy -= state.fontSize;

					svgs.push({ svg, svgx, svgy });
				} // have flipped anno
			
				dispatch({ type: "ImageDisplay/incrFinalizeCounter", payload: true });
				dispatch({ type: "ImageDisplay/setImageProcessing", payload: true });

				let rotation = 0.0;
				if (viewport && viewport.rotation)
					rotation = viewport.rotation;
				if (flip === "both") {
					flip = "none";
					rotation += 180.0;
				}

				let di = undefined, ei = undefined, tei = targetExposureIndexRef?.current;

				const toolstatePR = getToolState(enabledElement.element, "ProcessingROI");
				if (toolstatePR?.data) {
					const data = toolstatePR?.data[0];
					ei = data.exposureindex;
				}
				if(ei !== undefined && tei !== undefined)
					di = Math.round(1000.0 * Math.log10(ei / tei)) / 100;

				// @ts-ignore
				let artifactId: string = enabledElement.image?.targetId;
				if (artifactId.endsWith("/raw"))
					artifactId = artifactId.substring(0, artifactId.length - 4);

				FinalizeArtifact({
					artifactId: artifactId,
					instanceId: currentInstanceAsStringRef?.current ? JSON.parse(currentInstanceAsStringRef.current!)?.id : '',
					seriesId: currentInstanceAsStringRef?.current ? JSON.parse(currentInstanceAsStringRef.current!)?.id : '',
					workitemId: currentWorkitemIdRef?.current,
					previewId: "",
					rotate: rotation, flip: flip, cut: borders, blackmask: bm, darkening: dk, window: window, svgs: svgs,
					ei: ei !== undefined ? "" + Math.round(ei) : "",
					tei: tei !== undefined ? "" + Math.round(tei) : "",
					di: di !== undefined ? "" + Math.round(di) : "",
					setLaterality: setLaterality
				});
			} // have raw
			// else log error, wrt. no raw image?
		} // finalize
		if (toolName === 'Finalize' && currentWorkitemStateRef?.current === "COMPLETED") {
		    console.log("Reopen workitem.");

		    ResumeWorkitem({
				    // @ts-ignore
				    workitemId: currentWorkitemIdRef?.current
			});

		}
		if (toolName === 'StoreViewport') {
			if(currentWorkitemStateRef?.current !== "COMPLETED")
			if (storeViewport === ViewportStorageType.local) {
				const enabledElement: EnabledElement = getEnabledElement(ev.target);
				const image = cornerstone.getImage(enabledElement.element);

				let flip = "none";
				let rotation = 0.0;
				let scale = 1.0;
				let tx = 0.0, ty = 0.0;
				let borders = [undefined, undefined, undefined, undefined];
				let window = { width: 100, center: 50, name: "default" };

				let viewport = enabledElement?.viewport;
				if (viewport) {
					rotation = viewport.rotation ? viewport.rotation : 0.0;
					scale = viewport.scale ? viewport.scale : 1.0;
					tx = viewport.translation?viewport.translation.x:0.0;
					ty = viewport.translation?viewport.translation.y:0.0;

					// @ts-ignore
					if (image.cutwidth) {
						// @ts-ignore
						borders[0] = image.cutleft;
						// @ts-ignore
						borders[1] = image.cutright;
						// @ts-ignore
						borders[2] = image.cuttop;
						// @ts-ignore
						borders[3] = image.cutbottom;
					}

					if (viewport.hflip)
						flip = "horizontal";
					if (viewport.vflip)
						flip = "vertical";
					if (viewport.hflip && viewport.vflip)
						flip = "both";
					window.width = viewport.voi?.windowWidth ? viewport.voi?.windowWidth : 100;
					window.center = viewport.voi?.windowCenter ? viewport.voi?.windowCenter : 50;

					let jsvalue = JSON.stringify({
						"flip": flip, "rotation": rotation, "scale": scale, "tx": tx, "ty": ty, "ww": window.width, "wc": window.center,
						"cl": borders[0], "cr": borders[1], "ct": borders[2], "cb": borders[3], "width": image.width, "height": image.height
					});
					// @ts-ignore
					//console.log("storing value"+jsvalue+" to key "+image.targetId);

					// @ts-ignore
					localStorage.setItem(enabledElement.image?.targetId, jsvalue);
				}
			}
		} // StoreViewport
		if (toolName === 'ResetViewport') {
			const enabledElement: EnabledElement = getEnabledElement(ev.target);
			// TODO introduce/check global isStoreViewport setting
			// @ts-ignore
			localStorage.removeItem(enabledElement.image?.targetId);
		}
		// manage automatic flip annotation
		if (toolName === 'FlipHorizontal' || toolName === 'FlipVertical') {
			const enabledElement: EnabledElement = getEnabledElement(ev.target);
			const workitemLaterality = currentWorkitemLateralityRef?.current;
			const lrMarkerState = getToolState(enabledElement.element, "LRMarker");
			const haveLRMarker = (lrMarkerState && lrMarkerState.data && lrMarkerState.data.length !== 0);
			if (currentWorkitemStateRef?.current !== "COMPLETED" && workitemLaterality !== "left" && workitemLaterality !== "right" && !haveLRMarker) {
				const toolsListEntry: ToolsListEntry | undefined = findToolsByName(tools, 'FlippedAnnotation');				
				if (toolsListEntry && toolsListEntry.config) {
					const config = toolsListEntry.config;
					const targetIds: string[] | undefined = getTargetIdsForElement(enabledElement);
					let viewport = enabledElement?.viewport;
					if (viewport) {
						if (viewport.hflip || viewport.vflip) {
							// introduce "flipped"
							const flipAnnoState = getToolState(enabledElement.element, "FlippedAnnotation");
							if (!flipAnnoState || !flipAnnoState.data || flipAnnoState.data.length === 0) {
								const image = cornerstone.getImage(enabledElement.element);
								let xpos = image.width / 2, ypos = image.height / 2;

								if (config.place === "top-center") {									
									xpos = image.width / 2 - ((config.text?config.text.length:7)*config.size?config.size:144);
									ypos = config.size?config.size:50;
								}
								if (config.place === "bottom-center") {									
									xpos = image.width / 2 - ((config.text?config.text.length:7)*config.size?config.size:144);
									ypos = image.height-config.size?config.size:50;
								}
								if (viewport.hflip)
									xpos = image.width - xpos;
								if (viewport.vflip)
									ypos = image.height - ypos;

								let eventData = { event: { currentTarget: enabledElement.element }, element: enabledElement.element, currentPoints: { image: { x: xpos, y: ypos } } };
								let tool = cornerstoneTools.getToolForElement(enabledElement.element, "FlippedAnnotation");
								let measurement = tool.createNewMeasurement(eventData);
								addToolState(enabledElement?.element, "FlippedAnnotation", measurement);
								measurement.toolName = "FlippedAnnotation";
								tool.store(measurement);
								
								// @ts-ignore
								storeData(measurement, targetIds, dispatch, enabledElement);
							}
						} else {
							// remove "flipped"
							let toolData = getToolState(ev.target, "FlippedAnnotation");
							if (toolData !== undefined && toolData.data) {
								for (let i = 0; i < toolData.data.length; i++) {
									let data = toolData.data[i];
									removeToolState(ev.target, "FlippedAnnotation", data);
									// @ts-ignore
									revoke(ev.detail.element, data, targetIds, dispatch);
								}
							}
						}
						cornerstone.updateImage(ev.target, false);
					}
				}
			}
		}
		
		// store new/changed annotations
		let tool = cornerstoneTools.getToolForElement(ev.detail.element, toolName);

		if (tool?.hasIncomplete) {
			console.log("tool.hasIncomplete in onMeasureCompleted");
			return;
		}

		if (tool && tool.store) {

			tool.store(ev.detail.measurementData, dispatch);
			ev.detail.measurementData.toolName = toolName;
			// if we have an annotationId, this means we already have stored data and
			// should "edit" this rather than "store" a new data set
			const enabledElement: EnabledElement = getEnabledElement(ev.target);
			const targetIds: string[] | undefined = getTargetIdsForElement(enabledElement);
			if (ev.detail.measurementData.annotationId) {
				if (ev.detail.measurementData.annotationId !== -1) {
					// @ts-ignore
					edit(ev.detail.measurementData, targetIds, dispatch, enabledElement);
				}
			}
			else {
				// @ts-ignore
				storeData(ev.detail.measurementData, targetIds, dispatch, enabledElement);
			}
		}
	}; // onMeasureCompleted

	const onMeasureAdded = (ev: any) => {
		// some events come without toolName set, see if there is a name in the respective toolState
		let toolName = ev.detail.measurementData.toolName;
		if (ev.detail.toolName)
			toolName = ev.detail.toolName;
		if (toolName === 'BlackMask') {
			const data = ev.detail.measurementData;
			if (data) {
				if (data.cut) {
					setCutToBM(true);
				} else {
					setCutToBM(false);
				}
				if (data.locked) {
					setAcceptedBM(true);
				} else {
					setAcceptedBM(false);
				}
				if (data.angle && data.angle !== 0) {
					setRotateToBM(true);
				} else {
					setRotateToBM(false);
				}
			}
		} // if is BlackMask
	}; // onMeasureAdded

	const onMeasureRemoved = (ev: any) => {
		const enabledElement: EnabledElement = getEnabledElement(ev.target);
		if (enabledElement) {
			const targetIds: string[] | undefined = getTargetIdsForElement(enabledElement);
			// @ts-ignore
			revoke(ev.detail.element, ev.detail.measurementData, targetIds, dispatch);

			// inform the tool
			let toolName = ev.detail.measurementData.toolName;
			if (ev.detail.toolName)
				toolName = ev.detail.toolName;
			let tool = cornerstoneTools.getToolForElement(ev.detail.element, toolName);
			if (tool && tool.measurementRemoved) {
				tool.measurementRemoved(ev.detail.element, ev.detail.measurementData);
			}

			if (toolName === 'BlackMask') {
				setCutToBM(false);
				setAcceptedBM(false);
				setRotateToBM(false);
			}
		}
	};

	const onMouseDrag = throttle(1000, (ev: any) => {
		//console.log("onMouseDragThrottled");
		cornerstone.triggerEvent(ev.detail.element, cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED, {
			toolName: "StoreViewport",
			toolType: "StoreViewport",
			element: ev.detail.element,
			measurementData: undefined,
		});
	});

	// activate tools with click on corresponding annotation
	const onMouseClick = (ev: any) => {
		const currentMatrixIndex = getMatrixIndexFromEnabledElement(ev?.detail?.element)

		if (selectedToolRef?.current?.name !== 'EraserExtended') {

			const toolList = cornerstoneTools.store?.state?.tools?.filter(
				(tool: any) =>
					tool.element === ev?.detail?.element &&
					(tool.mode === 'active' ||
						tool.mode === 'passive' ||
						tool.mode === 'enabled') &&
					tool instanceof BaseAnnotationTool
			);
			let selectedToolstateIndex: number = 0;

			let nearTool: any = undefined;
			toolList.every((tool: any) => {
				const toolState = getToolState(tool.element, tool.name);
				let found: boolean = false;
				if (toolState && toolState.data && tool.pointNearTool) {
					for (let i = 0; i < toolState.data.length; i++) {
						const isNear: boolean = tool.pointNearTool(tool.element, toolState.data[i], ev?.detail?.currentPoints?.canvas, 'mouse');
						if (isNear) {
							nearTool = tool;
							selectedToolstateIndex = i;
							found = true;
							break;
						}
					}
					if (found) {
						return false;
					}
				}
				return true;
			});

			if (nearTool && !(nearTool.name === "FreehandRoiExtended" && nearTool?.mode === "active")) {
				//const selectedToolPar: ToolsListEntry | undefined = tools.find((tool: any) => tool.name === nearTool.name);
				const selectedToolPar: ToolsListEntry | undefined = toolsRef.current ? findToolsByName(toolsRef.current, nearTool.name) : undefined;
				if (selectedToolPar) {
					if (selectedToolRef.current && selectedToolRef.current?.name === nearTool.name) {
						activateToolContext(selectedToolPar.name, currentMatrixIndex);
					} else {
						handleSelect(selectedToolPar, currentMatrixIndex);
					}
					nearTool.selectedToolstateIndex = selectedToolstateIndex;
				}
			}
		}
	}

	const AddTools = (element: any, matrixIndex: number) => {
		if (!cornerstoneTools.getToolForElement(element?.element, "NewZoomMouseWheel")) cornerstoneTools.addToolForElement(element.element, NewZoomMouseWheelTool, {
			configuration: {
				invert: false,
				preventZoomOutsideImage: false,
				minScale: 0.01,
				maxScale: 20.0,
			},
		});

		if (!cornerstoneTools.getToolForElement(element?.element, "ZoomTouchPinch")) cornerstoneTools.addToolForElement(element.element, cornerstoneTools.ZoomTouchPinchTool, {
			mouseButtonMask: 2,
			configuration: {
				minScale: 0.01,
				maxScale: 20.0,
			},
		});

		const configuration_TextMarker = {
			markers: ['F5', 'F4', 'F3', 'F2', 'F1'],
			current: 'F5',
			ascending: true,
			loop: true,
			changeTextCallback,
		}

		const configuration_ArrowAnnotate = {
			getTextCallback,
			changeTextCallback,
			drawHandles: true,
			drawHandlesOnHover: drawHandlesOnHover,
			hideHandlesIfMoving: false,
			arrowFirst: true,
			renderDashed: false,
			allowEmptyLabel: false,
		}
		addAllToolsToElement(element, matrixIndex, configuration_TextMarker, configuration_ArrowAnnotate, drawHandlesOnHover);
	}

	useEffect(() => {
		const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();

		if (selectedMatrixIndex !== undefined && selectedMatrixIndex >= 0) {
			const element: EnabledElement | undefined = cornerstoneElements.find(
				(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
			);

			if (element && element.element) {
				dispatch({ type: "ImageDisplay/addMatrixSelectedTool", payload: { matrixIndex: selectedMatrixIndex, matrixSelectedTool: findToolsByName(tools, 'Pan') } });
				AddTools(element, selectedMatrixIndex);
				// viewport synchronization
				if (isViewportSynchronized) {
					element.element?.addEventListener('cornerstoneimagerendered', onImageRendered);
				}

				if (cornerstoneTools.getToolForElement(element.element, 'NewZoomMouseWheel')) {
					cornerstoneTools.setToolActiveForElement(element.element, "NewZoomMouseWheel", { mouseButtonMask: 3 });
				}
				if (cornerstoneTools.getToolForElement(element.element, 'ZoomTouchPinch')) {
					cornerstoneTools.setToolActiveForElement(element.element, "ZoomTouchPinch", { mouseButtonMask: 2 });
				}
				if (cornerstoneTools.getToolForElement(element.element, 'Wwwc')) {
					cornerstoneTools.setToolActiveForElement(element.element, "Wwwc", { mouseButtonMask: 2 });
				}
				/* if (cornerstoneTools.getToolForElement(element.element, 'Pan')) {
					cornerstoneTools.setToolActiveForElement(element.element, "Pan", { mouseButtonMask: 1 });
					const panToolsListEntry: ToolsListEntry | undefined = tools?.find((tool: ToolsListEntry) => tool.name === 'Pan');
					if (panToolsListEntry) {
						dispatch({ type: "ImageDisplay/setActiveToolIcon", payload: panToolsListEntry?.icon });
						console.log("useEffect 0 setMatrixSelectedTool: " + panToolsListEntry.name);
						dispatch({ type: "ImageDisplay/setMatrixSelectedTool", payload: { matrixIndex: selectedMatrixIndex, matrixSelectedTool: panToolsListEntry } });
					}
				} */

				const currentToolName = currentSelectedTool?.name ?? 'Pan';
				const currentToolIcon = currentSelectedTool?.icon ?? '/images/arrows.svg';

				const toolList = cornerstoneTools.store?.state?.tools?.filter(
					(tool: any) =>
						tool.element === element.element
				);

				toolList?.filter((tool: any) => tool.name !== 'NewZoomMouseWheel' && tool.name !== 'ZoomTouchPinch' && tool.name !== 'Wwwc')?.forEach((tool: any) => {
					if (currentToolName === tool.name) {
						cornerstoneTools.setToolActiveForElement(element.element, tool.name, { mouseButtonMask: 1, reset: true });
						dispatch({ type: "ImageDisplay/setActiveToolIcon", payload: currentToolIcon });
					} else {
						cornerstoneTools.setToolPassiveForElement(element.element, tool.name, { mouseButtonMask: 1, reset: true });
					}
				});

				/* tools?.forEach((object) => {
					//if (object.name !== 'Pan') {

					if (object?.subMenu && Array.isArray(object?.subMenu) && object?.subMenu.length > 0) {
						object?.subMenu?.forEach((object) => {
							if (currentToolName === object.name) {
								console.log("set tool active:  " + object.name);
								cornerstoneTools.setToolActiveForElement(element.element, object.name, { mouseButtonMask: 1, reset: true });
								dispatch({ type: "ImageDisplay/setActiveToolIcon", payload: currentToolIcon });
							} else {
								console.log("set tool passive:  " + object.name);
								cornerstoneTools.setToolPassiveForElement(element.element, object.name, { mouseButtonMask: 1, reset: true });
							}
						});
					} else {
						if (currentToolName === object.name) {
							console.log("set tool active:  " + object.name);
							cornerstoneTools.setToolActiveForElement(element.element, object.name, { mouseButtonMask: 1, reset: true });
							dispatch({ type: "ImageDisplay/setActiveToolIcon", payload: currentToolIcon });
						} else {
							console.log("set tool passive:  " + object.name);
							cornerstoneTools.setToolPassiveForElement(element.element, object.name, { mouseButtonMask: 1, reset: true });
						}
					}
					//}
				}); */

				element.element?.addEventListener(
					cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED,
					onMeasureCompleted
				);
				element.element?.addEventListener(
					cornerstoneTools.EVENTS.MEASUREMENT_ADDED,
					onMeasureAdded
				);
				element.element?.addEventListener(
					cornerstoneTools.EVENTS.MEASUREMENT_REMOVED,
					onMeasureRemoved
				);
				element.element?.addEventListener(
					cornerstoneTools.EVENTS.MOUSE_CLICK,
					onMouseClick
				);
				element.element?.addEventListener(
					cornerstoneTools.EVENTS.MOUSE_DRAG,
					onMouseDrag
				);
				element.element?.addEventListener(
					cornerstoneTools.EVENTS.TOUCH_PRESS,
					onMouseClick
				);
			}
		}

		return () => {

			dispatch({ type: "ImageDisplay/setActiveToolIcon", payload: '' });

			const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();

			if (selectedMatrixIndex !== undefined && selectedMatrixIndex >= 0) {
				const element: EnabledElement | undefined = cornerstoneElements.find(
					(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
				);

				if (element && element.element) {
					element.element.style.cursor = "initial";

					if (cornerstoneTools.getToolForElement(element.element, 'NewZoomMouseWheel')) {
						cornerstoneTools.setToolPassiveForElement(element.element, 'NewZoomMouseWheel');
					}
					if (cornerstoneTools.getToolForElement(element.element, 'ZoomTouchPinch')) {
						cornerstoneTools.setToolPassiveForElement(element.element, 'ZoomTouchPinch');
					}

					const toolList = cornerstoneTools.store?.state?.tools?.filter(
						(tool: any) =>
							tool.element === element.element && tool.name !== 'NewZoomMouseWheel' && tool.name !== 'ZoomTouchPinch' && tool.name !== 'Wwwc'
					);
					toolList?.forEach((tool: any) => {
						cornerstoneTools.setToolPassiveForElement(element.element, tool.name, { mouseButtonMask: 1, reset: true });
					});

					/* tools?.forEach(tool => {
						if (tool?.subMenu && Array.isArray(tool?.subMenu) && tool?.subMenu.length > 0) {
							tool?.subMenu?.forEach((tool) => {
								if (cornerstoneTools.getToolForElement(element.element, tool.name)) {
									cornerstoneTools.setToolPassiveForElement(element.element, tool.name, { mouseButtonMask: 1, reset: false });
								}
							});
						} else {
							if (cornerstoneTools.getToolForElement(element.element, tool.name)) {
								cornerstoneTools.setToolPassiveForElement(element.element, tool.name, { mouseButtonMask: 1, reset: false });
							}
						}
					}); */

					element.element?.removeEventListener(
						cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED,
						onMeasureCompleted
					);
					element.element?.removeEventListener(
						cornerstoneTools.EVENTS.MEASUREMENT_ADDED,
						onMeasureAdded
					);
					element.element?.removeEventListener(
						cornerstoneTools.EVENTS.MEASUREMENT_REMOVED,
						onMeasureRemoved
					);
					element.element?.removeEventListener(
						cornerstoneTools.EVENTS.MOUSE_CLICK,
						onMouseClick
					);
					element.element?.removeEventListener(
						cornerstoneTools.EVENTS.MOUSE_DRAG,
						onMouseDrag
					);
					element.element?.removeEventListener(
						cornerstoneTools.EVENTS.TOUCH_PRESS,
						onMouseClick
					);

					const matrixIndex = getMatrixIndexFromEnabledElement(element.element);
					if (matrixIndex >= 0) {
						if (isViewportSynchronized && matrixIndex === selectedMatrixIndex) {
							element.element?.removeEventListener('cornerstoneimagerendered', onImageRendered);
						}
					}
				}
			}
		}
	}, [props.orderId, currentMatrixColumns, currentMatrixRows, selectedMatrixIndex, dispatch,
		isViewportSynchronized, onImageRendered, isAcquisitionButtonActive]);


	useEffect(() => {
		const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();

		if (selectedMatrixIndex !== undefined && selectedMatrixIndex >= 0) {
			const element: EnabledElement | undefined = cornerstoneElements.find(
				(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
			);
			if (element && element.element) {
				if (cornerstoneTools.getToolForElement(element.element, 'Pan')) {
					cornerstoneTools.setToolActiveForElement(element.element, "Pan", { mouseButtonMask: 1 });
					const panToolsListEntry: ToolsListEntry | undefined = findToolsByName(tools, 'Pan');
					if (panToolsListEntry) {
						dispatch({ type: "ImageDisplay/setActiveToolIcon", payload: panToolsListEntry?.icon });
						dispatch({ type: "ImageDisplay/setMatrixSelectedTool", payload: { matrixIndex: selectedMatrixIndex, matrixSelectedTool: panToolsListEntry } });
					}
				}
			}
		}
	}, [isAcquisitionButtonActive, dispatch]);

	useEffect(() => {
		// activate pan with key 'p'
		const onKeyDown = ((event: any) => {
			var name = event.key;
			if (name === panActivateKey && !isKeyDown) {
				isKeyDown = true;
				const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
				const element: EnabledElement | undefined = cornerstoneElements.find(
					(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
				);
				if (element?.element) {
					if (cornerstoneTools.getToolForElement(element.element, 'Pan')) {
						cornerstoneTools.setToolActiveForElement(element.element, "Pan", { mouseButtonMask: 1 });
					}
				}
			}
			if (name === "ArrowLeft" || name === "ArrowRight") {
				if (selectedToolRef.current) {
					if (selectedToolRef.current.name === "RotateToBlackMask" || selectedToolRef.current.name === "Rotate") {
						const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
						const element: EnabledElement | undefined = cornerstoneElements.find(
							(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
						);
						if (element) {
							let viewport = element?.viewport;
							if (viewport) {
								if (viewport?.rotation !== undefined) {
									if (name === "ArrowLeft")
										viewport.rotation += 0.5;
									else
										viewport.rotation -= 0.5;
									const image = cornerstone.getImage(element.element);
									// @ts-ignore
									if (image.cutwidth) {
										const rotationRad = viewport.rotation * Math.PI / 180.0;
										// @ts-ignore
										let tx = (image.width / 2 - image.cutx), ty = (image.height / 2 - image.cuty);
										if (viewport.hflip)
											// @ts-ignore
											tx = image.width / 2 - (image.width - image.cutx);
										if (viewport.vflip)
											// @ts-ignore
											ty = image.height / 2 - (image.height - image.cuty);
										let ttx = (tx * Math.cos(rotationRad) - ty * Math.sin(rotationRad));
										let tty = (ty * Math.cos(rotationRad) + tx * Math.sin(rotationRad));

										viewport.translation = { x: ttx, y: tty };
									}
									cornerstone.updateImage(element?.element);
								}
							}
						}
					}
				}
			}

		})

		// reactivate selected tool
		const onKeyUp = ((event: any) => {
			var name = event.key;
			if (name === panActivateKey) {
				isKeyDown = false;
				const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
				const element: EnabledElement | undefined = cornerstoneElements.find(
					(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
				);
				if (element?.element && selectedToolRef.current) {
					if (cornerstoneTools.getToolForElement(element.element, selectedToolRef.current.name)) {
						cornerstoneTools.setToolActiveForElement(element.element, selectedToolRef.current.name, { mouseButtonMask: 1 });
					}
				}
			}
		})

		document.addEventListener('keydown', onKeyDown, false);
		document.addEventListener('keyup', onKeyUp, false);
		return () => {
			document.removeEventListener('keydown', onKeyDown, false);
			document.removeEventListener('keyup', onKeyUp, false);
		};
	}, [props.orderId, selectedMatrixIndex]);


	useEffect(() => {
		const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();

		if (selectedMatrixIndex !== undefined && selectedMatrixIndex >= 0) {
			const element: EnabledElement | undefined = cornerstoneElements.find(
				(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
			);
			setIsInverted(element?.viewport?.invert);
			setIsVflip(element?.viewport?.vflip);
			setIsHflip(element?.viewport?.hflip);
			setCutToBM(false);
			setRotateToBM(false);
			setAcceptedBM(false);
			if (element && element.element) {
				const toolState = getToolState(element.element, "BlackMask");
				if (toolState && toolState.data) {
					for (let i = 0; i < toolState.data.length; i++) {
						const data = toolState.data[i];
						if (data.cut)
							setCutToBM(true);
						if (data.locked)
							setAcceptedBM(true);
						if (data.angle && data.angle !== 0)
							setRotateToBM(true);
					}
				}
				if(currentWorkitemStateRef?.current === "COMPLETED") {
				    if ((selectedToolRef.current !== undefined) &&
				        (selectedToolRef.current.name === "LRMarker" ||
				         selectedToolRef.current.name === "BlackMask" ||
				         selectedToolRef.current.name === "Whitepoint")) {
			              if (cornerstoneTools.getToolForElement(element.element, 'Pan')) {
					            cornerstoneTools.setToolActiveForElement(element.element, "Pan", { mouseButtonMask: 1 });
					            const panToolsListEntry: ToolsListEntry | undefined = findToolsByName(tools, 'Pan');
					            if (panToolsListEntry) {
						            dispatch({ type: "ImageDisplay/setActiveToolIcon", payload: panToolsListEntry?.icon });
						            dispatch({ type: "ImageDisplay/setMatrixSelectedTool", payload: { matrixIndex: selectedMatrixIndex, matrixSelectedTool: panToolsListEntry } });
					            }
				          }
				    }
				}
			}
		}
	}, [currentWorkitemId, selectedMatrixIndex, imageLoadStatus]);

	useEffect(() => {
		dispatch({
			type: "ImageDisplay/setFFTFilterActive", payload: {
				matrixIndex: selectedMatrixIndex,
				isActive: false, originalImage: ''
			}
		})

	}, [currentWorkitemId, dispatch]);


	useEffect(() => {

		const toolList = cornerstoneTools.store?.state?.tools?.filter(
			(tool: any) =>
				(tool.mode === 'active' ||
					tool.mode === 'passive' ||
					tool.mode === 'enabled') &&
				tool instanceof BaseAnnotationTool
		);


		const elem = document.getElementById('toolsPanel');
		if (elem) {
			const toolColor = getComputedStyle(elem)?.getPropertyValue('--toolColor');
			const toolActiveColor = getComputedStyle(elem)?.getPropertyValue('--toolActiveColor');
			const selectedColor = getComputedStyle(elem)?.getPropertyValue('--toolSelectedColor');
			const measurementAcceptedColor = getComputedStyle(elem)?.getPropertyValue('--tool_measurement_accepted');
			const measurementAcceptedSelectedColor = getComputedStyle(elem)?.getPropertyValue('--tool-measurement_accepted_selected');
			const measurementAcceptedActiveColor = getComputedStyle(elem)?.getPropertyValue('--tool-measurement_accepted_active');
			toolList.forEach((tool: any) => {
				if (tool !== undefined) {
					if (toolColor) {
						tool.toolColor = toolColor;
					}
					if (toolActiveColor) {
						tool.toolActiveColor = toolActiveColor;
					}
					if (selectedColor) {
						tool.toolSelectedColor = selectedColor;
					}
					if (measurementAcceptedColor) {
						tool.toolMeasurementAcceptedColor = measurementAcceptedColor;
					}
					if (measurementAcceptedSelectedColor) {
						tool.toolMeasurementAcceptedSelectedColor = measurementAcceptedSelectedColor;
					}
					if (measurementAcceptedActiveColor) {
						tool.toolMeasurementAcceptedActiveColor = measurementAcceptedActiveColor;
					}

					tool.svgFilter = getFilterForColor(toolColor);
					tool.svgFilterActive = getFilterForColor(toolActiveColor);
					tool.svgFilterSelected = getFilterForColor(selectedColor)
				}

			});
			if (toolColor) {
				cornerstoneTools.toolColors.setToolColor(toolColor);
			}
		}

	}, [currentTheme]);


	// redraw annotations for language changed
	useEffect(() => {
		const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
		const activeElements = cornerstoneElements.filter(
			(element: EnabledElement) => element.element.id.startsWith(`${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_`));
		if (activeElements) {
			activeElements?.forEach((element: any) => {
				if (element?.element) {
					cornerstone.updateImage(element?.element);
				}
			});
		}
	}, [language]);

	useEffect(() => {
		const onBodyClick = (event: Event) => {
			if (rootDivElement.current?.contains(event.target as Node)) {
				return;
			}
			//if (!selectedToolPar.sticky)
			setOpenSubmenuItem('');
			setIsOverflowVisible(false);
		};
		document.body.addEventListener("click", onBodyClick, { capture: true });
		return () => {
			document.body.removeEventListener("click", onBodyClick, { capture: true });

		};
	}, []);

	const isToolButtonActive = (selectedtool: ToolsListEntry | undefined, tool: ToolsListEntry): boolean => {

		return (selectedtool && ((selectedtool.id || tool.id) ? (selectedtool.id === tool.id) : (selectedtool.name === tool.name))) ||
			(tool.name === 'FFTFilter' && isFFTFilterActive) ||
			(tool.name === 'Invert' && isInverted) ||
			(tool.name === 'FlipVertical' && isVflip) ||
			(tool.name === 'FlipHorizontal' && isHflip) ||
			(tool.name === 'FalseColor' && isFalseColorsActive) ||
			(tool.name === 'CutToBlackMask' && isCutToBM) ||
			(tool.name === 'AcceptBlackMask' && isAcceptedBM) ||
			(tool.name === 'RotateToBlackMask' && isRotateToBM) ||
			/* (tool.name === 'Whitepoint' && currentWorkitemState !== "COMPLETED" && _isJustProcessedAndNoToolActivated()) || */
			(tool.name === 'Finalize' && currentWorkitemState === "COMPLETED");
	}

	const toolsLeftEllipsis = (node: any) => {
		const toolsRendered = node.props.children;
		toolsRenderedLength = toolsRendered.length;
		//console.log("toolsLeftEllipsis:" + toolsRenderedLength);

		//return `+${tools.length - toolsRendered.length} more`;
		return '';
	};

	const toolButton = (object: ToolsListEntry) => {
		return object?.config?.omitFromToolbar ? null : <div className="toolButtonWrapper" key={object.id ? object.id : object.name}>
			<button id={object.name} className={(isToolButtonActive(currentSelectedTool, object)) ? "toolButtonActive" : "toolButton"} title={t(object.id ? object.id : object.name) as string}
				onMouseDown={() => {
					openSubmenuItem === object?.name ?
						setOpenSubmenuItem('') :
						handleSelect(object, selectedMatrixIndex ?? -1)
				}}>
				<ToolButtonImage toolName={object.name} icon={object.icon} name={object.name} cssclass={(isToolButtonActive(currentSelectedTool, object)) ? "toolButtonActiveImg" : "toolButtonImg"} />
				{object?.subMenu?.length > 0 ?
					<img
						src={`${imagePathForCurrentTheme}triangle-right-svgrepo-com.svg`}
						onError={(event: any) => { event.target.src = "/images/triangle-right-svgrepo-com.svg"; event.onerror = null }}
						alt="triangle-right-svgrepo-com.svg" className="submenuTriangle" />
					: null}
			</button>
			{(function () {
				if (object?.subMenu?.length > 0 && openSubmenuItem === object?.name) {
					return <ul className="subMenu" key={`${object.name}2`}>
						{object?.subMenu?.map((item, i) => (
							<li key={i.toString()} onMouseDown={() => handleSelect(item, selectedMatrixIndex ?? -1)} >
								<span className="subMenuItem">
									<img src={item.icon} alt={item.name} className={isToolButtonActive(currentSelectedTool, item) ? "subMenuItemImgActive" : "subMenuItemImg"} />
									<p>{t(item.name)}</p>
								</span>
								{i === object?.subMenu?.length - 1 ? null :
									<span className="vl" />
								}
							</li>
						))}
					</ul>
				} else {
					return null;
				}
			})()}
		</div>
	}

	return (
		<>
			{layout?.display_tool_in_navbar ?
				<>
					<TruncateMarkup lines={1} lineHeight="38px" ellipsis={toolsLeftEllipsis}
						onTruncate={wasTruncated => {
							setToolsbuttonsOverflow(toolsRenderedLength);
						}}
					>
						<div className="tool-button-list" ref={rootDivElement}>
							{tools?.map((object, i) => (
								<TruncateMarkup.Atom key={object.name}>
									{toolButton(object)}
								</TruncateMarkup.Atom>
							))}
						</div>

					</TruncateMarkup>
					<button className="toolContextButton more-botton" onMouseDown={() => setIsOverflowVisible(!isOverflowVisible)} title={`${t("moreTools")}`}>
						...
					</button>

					<div className={isOverflowVisible ? "tool-button-list2" : "tool-button-list2-invisible"} >
						{tools?.filter((tool, i) => i >= toolsbuttonsOverflow - 1).map((object, i) => (
							toolButton(object)
						))}
					</div>
				</>
				:
				<div className="tool-button-list" ref={rootDivElement}>
					{tools?.map((object, i) => (
						toolButton(object)
					))}
				</div>
			}
		</>
	);
};

export default Tools;
