type colorSetting = {
    active: string;
    inactive: string;
};

interface WifiLevelProps {
    colorSettingNr: number;
    wifiLevel: number;
}

const WifiLevel = (props: WifiLevelProps) => {


    const colorSettings: colorSetting[] = [{ active: "#000", inactive: "#999"},
                                           { active: "#F2F2F2", inactive: "#999"}];

    let arcs = [];

    let i_level = Math.round(props.wifiLevel / 25);

    arcs.push(<path d="m286.12 385.61c-16.606-16.607-43.629-16.607-60.235 0-16.607 16.607-16.607 43.629 0 60.236 8.303 8.304 19.211 12.456 30.117 12.456s21.815-4.151 30.117-12.456c16.608-16.606 16.608-43.629 1e-3 -60.236z" fill={i_level > 0 ? colorSettings[props.colorSettingNr].active : colorSettings[props.colorSettingNr].inactive}  key="1"/>);
    arcs.push(<path d="m150.59 310.32 30.117 30.117c41.518-41.517 109.07-41.517 150.59 0l30.117-30.117c-58.122-58.123-152.7-58.123-210.82 0z" fill={i_level > 1 ? colorSettings[props.colorSettingNr].active : colorSettings[props.colorSettingNr].inactive} key="2"/>);
    arcs.push(<path d="m256 160.18c-68.262 0-132.44 26.582-180.71 74.851l30.117 30.117c40.223-40.223 93.704-62.376 150.59-62.376s110.36 22.153 150.59 62.376l30.117-30.117c-48.266-48.269-112.44-74.851-180.7-74.851z" fill={i_level > 2 ? colorSettings[props.colorSettingNr].active : colorSettings[props.colorSettingNr].inactive} key="3"/>);
    arcs.push(<path d="m256 53.694c-96.704 0-187.62 37.659-256 106.04l30.117 30.117c60.336-60.335 140.56-93.563 225.88-93.563s165.55 33.228 225.88 93.564l30.117-30.118c-68.38-68.38-159.3-106.04-256-106.04z" fill={i_level > 3 ? colorSettings[props.colorSettingNr].active : colorSettings[props.colorSettingNr].inactive} key="4"/>);

    return (
        <svg enableBackground="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" className="wifiLevel">
            <g transform="rotate(-90,256,256)">
                <g>
                    {arcs}
                </g>
            </g>
        </svg>
    );

}

export default WifiLevel;
