import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useCancelStudyMutation, useCompleteStudyMutation } from "../../apis/apiSlice";
import { Constants } from "../../Constants";
import { AppDispatch, RootState } from "../../store";
import { selectOrderInfoVisible } from "../ImageDisplay/ImageDisplaySlice";
import { submit } from "../Utils/ConfirmDialog";
import ItemActionButtonGroup from "../Utils/ItemActionButtonGroup";
import './BurgerMenu.scss';
import { useState } from "react";
import { useORTranslation } from "../Localization/ORLocalization";
import { getImagePathForCurrentTheme } from "./OrdersSlice";

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const BurgerMenu = (props: { orderId: string }) => {
	const dispatch = useAppDispatch();

	const { t } = useORTranslation(['InfoPanel']);

	const [completeStudyDetails] = useCompleteStudyMutation();
	const [cancelStudyDetails] = useCancelStudyMutation();

	const [callbacksActive, setCallbacksActive] = useState<boolean>(true);

	const isOrderInfoVisible: boolean = useAppSelector((state) => selectOrderInfoVisible(state));
	const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

	const handleClick = (e: React.MouseEvent | React.TouchEvent) => {
		//console.log("handleClick");
		e.stopPropagation();
		setCallbacksActive(true);
	}

	const dotsButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
		e.stopPropagation();
		dispatch({ type: "Orders/setCurrentOrderId", payload: props.orderId });
		dispatch({ type: "ImageDisplay/setExportDialogVisible", payload: 2 });
	}

	const editButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
		e.stopPropagation();
		dispatch({ type: "Orders/setCurrentOrderId", payload: props.orderId });
		dispatch({ type: "ImageDisplay/setOrderInfoVisible", payload: !isOrderInfoVisible });
	};

	const completeButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
		e.stopPropagation();
		completeStudyDetails({ orderId: props.orderId }).unwrap();
	};

	const finalizeStudyButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
		console.log("Finalize Study from BurgerMenu.");
		e.stopPropagation();
		dispatch({ type: "Orders/setCurrentOrderId", payload: props.orderId });
		dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 2 });
	};

	const cancelButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
		e.stopPropagation();
		//dispatch(deleteOrder({ orderId: props.orderId }));
		cancelStudyDetails({ orderId: props.orderId }).unwrap();
	};

	return (
		<div className="burger-menu" onTouchStart={(e) => {
			//console.log("touch");
			setCallbacksActive(false);
			e.stopPropagation();
		}
		}>
			<div className="burger-menu-icon">
				<img className="burger-menu-icon-img" src={`${imagePathForCurrentTheme}burger-menu-icon.svg`}
					onError={(event: any) => { event.target.src = "/images/burger-menu-icon.svg"; event.onerror = null }}
					alt="burger-menu-icon.svg" />
				{/* <div className="burger-menu-bars" />
				<div className="burger-menu-bars" />
				<div className="burger-menu-bars" /> */}
			</div>

			<div className="itemActionButtonGroup" onClick={(e) => { e.stopPropagation() }}
				onTouchStart={(e) => { e.stopPropagation() }}>
				<ItemActionButtonGroup
					callbacks={callbacksActive ? {
						downloadButtonClick: dotsButtonClick,
						editButtonClick: editButtonClick,
						finalizeStudyButtonClick: finalizeStudyButtonClick,
						closeButtonClick: () => { submit([t(`confirmationClose${Constants.ORDER_IDENTIFIER}`), t('confirmationQuestion')], completeButtonClick) },
						cancelButtonClick: () => { submit([t(`confirmationCancel${Constants.ORDER_IDENTIFIER}`), t('confirmationQuestion')], cancelButtonClick) }
					} : {
						downloadButtonClick: handleClick,
						editButtonClick: handleClick,
						closeButtonClick: handleClick,
						cancelButtonClick: handleClick,
						finalizeStudyButtonClick: handleClick
					}}
					titles={{
						downloadButtonClick: t("exportImages") as string,
						editButtonClick: t(`edit${Constants.ORDER_IDENTIFIER}Info`) as string,
						closeButtonClick: t(`complete${Constants.ORDER_IDENTIFIER}`) as string,
						cancelButtonClick: t(`cancel${Constants.ORDER_IDENTIFIER}`) as string,
						finalizeStudyButtonClick: t(`completeStudy`) as string,
					}} />
			</div>
		</div>
	);
}

export default BurgerMenu;
